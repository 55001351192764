import { Flag } from '@mui/icons-material';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPublisher, deletePublisher, updatePublisher } from '../../Services/publisherTabApis';
import { createPublisherCamp, deletePublisherCamp, updatePublisherCamp } from '../../Services/publisherTabApis';

interface Publisher {
  Publi_ID: string;
  Publisher_Name: string;
  // Created_on: string;
  Publisher_Campaign_Count: number;
  // Website: string;
  Status: string;
  // Add other properties here
}

interface PublisherListState {
  data: Publisher[];
  loading: boolean;
  currentCursor: string | null;
  hasMoreData: boolean;
  flag: boolean;
  fullData: any;
  compApiData: Publisher[];
  compApiCpcData: Publisher[];
  fullCpcData: any;
  cpcData: Publisher[];
  // fullApiData:any;
}

const initialState: PublisherListState = {
  data: [],
  loading: false,
  currentCursor: null,
  hasMoreData: true,
  flag: false,
  fullData: [],
  compApiData: [],
  cpcData: [],
  compApiCpcData: [],
  fullCpcData: [],
};

export const createPublisherAsync = createAsyncThunk(
  'publisherAccApi/createPublisher',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await createPublisher(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Define an async thunk for updating an publisher
export const updatePublisherAsync = createAsyncThunk(
  'publisherAccApi/updatePublisher',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updatePublisher(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePublisherAsync = createAsyncThunk(
  'publisherAccApi/deletePublisher',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deletePublisher(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


const publisherAccApiSlice = createSlice({
  name: 'publisherList',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Publisher[]>) => {
      state.data = action.payload;
    },
    setCpcData: (state, action: PayloadAction<Publisher[]>) => {
      state.cpcData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurrentCursor: (state, action: PayloadAction<string | null>) => {
      state.currentCursor = action.payload;
    },
    setHasMoreData: (state, action: PayloadAction<boolean>) => {
      state.hasMoreData = action.payload;
    },

    appendData: (state, action: PayloadAction<Publisher[]>) => {
      if (state.flag) {
        // console.log("yes");
        const sortData = [...state.data, ...action.payload];
        sortData.sort((a, b) => {
          const nameA = a.Publisher_Name?.toUpperCase(); // Ignore case during sorting
          const nameB = b.Publisher_Name?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        state.data = sortData;
      } else {
        // console.log("no");
        const sortData = action.payload;
        sortData.sort((a, b) => {
          const nameA = a.Publisher_Name?.toUpperCase(); // Ignore case during sorting
          const nameB = b.Publisher_Name?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        state.data = sortData;
      }
    },

    appendCpcData: (state, action: PayloadAction<Publisher[]>) => {
      if (state.flag) {
        // console.log("yes");
        const sortData = [...state.cpcData, ...action.payload];
        sortData.sort((a, b) => {
          const nameA = a.Publisher_Name?.toUpperCase(); // Ignore case during sorting
          const nameB = b.Publisher_Name?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        state.cpcData = sortData;
      } else {
        // console.log("no");
        const sortData = action.payload;
        sortData.sort((a, b) => {
          const nameA = a.Publisher_Name?.toUpperCase(); // Ignore case during sorting
          const nameB = b.Publisher_Name?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        state.cpcData = sortData;
      }
    },

    setFlag: (state, action: PayloadAction<boolean>) => {
      state.flag = action.payload;
    },
    setFullData: (state, action: PayloadAction<any>) => {
      state.fullData = action.payload;
    },
    setFullCpcData: (state, action: PayloadAction<any>) => {
      state.fullCpcData = action.payload;
    },
    setApiFullData: (state, action: PayloadAction<any>) => {
      state.compApiData = action.payload;
    },
    setApiFullCpcData: (state, action: PayloadAction<any>) => {
      state.compApiCpcData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPublisherAsync.fulfilled, (state, action) => {
        // Handle the successful creation of an advertiser if needed
      })
      .addCase(updatePublisherAsync.fulfilled, (state, action) => {
        // Handle the successful update of an advertiser if needed
      });
  },
});



interface PublisherCamp {
  campaign_id: string;
  campaign_name: string;
  Publisher_ID: string;
  Publisher_Name: string;
  Status: string;
  // Add other properties here
}

interface PublisherCampState {
  datacamp: PublisherCamp[];
  campLoading: boolean;
  currentCampCursor: string | null;
  hasCampMoreData: boolean;
  campFlag: boolean;
  fullDataCamp: any;
  compApiDataCamp: PublisherCamp[];
  filterSearchPubData: [];
  filterSearchFlag: boolean;
  frontEndFilSearch: string;

}

const initialCampState: PublisherCampState = {
  datacamp: [],
  campLoading: false,
  currentCampCursor: null,
  hasCampMoreData: true,
  campFlag: false,
  fullDataCamp: [],
  compApiDataCamp: [],
  filterSearchPubData: [],
  filterSearchFlag: true,
  frontEndFilSearch: '',
};

export const createPublisherCampAsync = createAsyncThunk(
  'publisherCamApi/createPublisherCamp',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await createPublisherCamp(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Define an async thunk for updating an publisher
export const updatePublisherCampAsync = createAsyncThunk(
  'publisherCampApi/updatePublisherCamp',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updatePublisherCamp(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePublisherCampAsync = createAsyncThunk(
  'publisherCampApi/deletePublisherCamp',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deletePublisherCamp(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


const publisherCampApiSlice = createSlice({
  name: 'publisherCampList',
  initialState: initialCampState,
  reducers: {
    setCampData: (state, action: PayloadAction<PublisherCamp[]>) => {
      state.datacamp = action.payload;
    },
    ///filterSearchData
    setFilterSearchPubData: (state, action: PayloadAction<[]>) => {
      state.filterSearchPubData = action.payload;
    },
    setFrontEndFilSearch: (state, action: PayloadAction<any>) => {
      state.frontEndFilSearch = action.payload;
    },
    setCampLoading: (state, action: PayloadAction<boolean>) => {
      state.campLoading = action.payload;
    },
    setCurrentCampCursor: (state, action: PayloadAction<string | null>) => {
      state.currentCampCursor = action.payload;
    },
    setHasCampMoreData: (state, action: PayloadAction<boolean>) => {
      state.hasCampMoreData = action.payload;
    },

    appendCampData: (state, action: PayloadAction<PublisherCamp[]>) => {
      if (state.campFlag) {
        state.datacamp = [...state.datacamp, ...action.payload];
      } else {
        state.datacamp = action.payload;
      }
    },
    setFullCampData: (state, action: PayloadAction<PublisherCamp[]>) => {
      if (state.campFlag) {
        state.fullDataCamp = [...state.fullDataCamp, ...action.payload];
      } else {
        state.fullDataCamp = action.payload;
      }
    },

    setfilterSearchFlag: (state, action: PayloadAction<boolean>) => {
      state.filterSearchFlag = action.payload;
    },
    setCampFlag: (state, action: PayloadAction<boolean>) => {
      state.campFlag = action.payload;
    },
    setFullCampDataa: (state, action: PayloadAction<any>) => {
      state.fullDataCamp = action.payload;
    },
    setApiFullCampData: (state, action: PayloadAction<any>) => {
      state.compApiDataCamp = action.payload;
    },
  },
});

export const { setFullCampDataa, setCampData, setFilterSearchPubData, setFrontEndFilSearch, setCampLoading, setCurrentCampCursor, setHasCampMoreData, appendCampData, setCampFlag, setfilterSearchFlag, setFullCampData, setApiFullCampData } = publisherCampApiSlice.actions;
export const publisherCampApi = publisherCampApiSlice.reducer;

export const { setCpcData, setFullCpcData, setApiFullCpcData, appendCpcData, setData, setLoading, setCurrentCursor, setHasMoreData, appendData, setFlag, setFullData, setApiFullData } = publisherAccApiSlice.actions;
export default publisherAccApiSlice.reducer;




