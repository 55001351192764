import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Chip, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import { tcpaHeadCell, tcpaRows } from '../../Component/Table/tableInterface'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { setBlockScroll, setNoDataFlag, setScrollNext } from '../../Store/Slices/advertiserTabApisData'
import Search from '../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import { fetchMBrandApiData } from '../../Services/externalApis'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIconPop } from '../Advertiser/Action'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { fetchCompApiData } from '../../Services/advertiserTabApis'
import { fetchTcpaAction } from '../../Store/Actions'
import { fetchTcpaCampaignData, fetchTcpaSearchResult, filterTcpaSearch } from '../../Services/tcpaTabApis'
import { resetSearchFilterData, resetverticalFilter, setFields, setPayloadTcpaId, setSearchFilterData, setTcpaCampaignIdOnEdit, setTcpaCreateJson, setTcpaData, setTcpaEditFlag, setTcpaEditJson, setTcpafilterSearchFlag, setTcpaFlag, setTcpaSearchInput, updateStateForTCPAEditAction } from '../../Store/Slices/tcpaCampSlice'
import { campaignFormSelector, resetState } from '../../Store/Slices/CreateAdvCampaignSlice';
import CloseIcon from '@mui/icons-material/Close';
import UploadTCPAContent from './UploadTCPAContent'
import CreateCampaignPopup from '../Advertiser/Stepper Form/clicksCampVerticalPopup'
import { fetchVerticals } from '../../Services/verticalList'
import { RootState } from '../../Store'


const TCPAcampaigns = () => {

  const dispatch = useAppDispatch();
  const tcpaTableData = useAppSelector((state) => state.tcpaCampSlice.tcpaData);
  const tcpaFullData = useAppSelector((state) => state.tcpaCampSlice.tcpaFullData);
  const fullData = useAppSelector((state) => state.advertiserAccApi.fullData);
  // const isLoading = useAppSelector((state) => state.tcpaCampSlice.loading);
  const isLoading = useAppSelector((state) => state.tcpaCampSlice.loading);
  const currentCursor = useAppSelector((state) => state.tcpaCampSlice.currentCursor);
  const hasMoreData = useAppSelector((state) => state.tcpaCampSlice.hasMoreData);
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const tcpaSearchInput = useAppSelector((state) => state.tcpaCampSlice.tcpaSearchInput);
  // const [openPopup, setOpenPopup] = React.useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const advCampaignFormState = useAppSelector(campaignFormSelector);
  const advAPIPath = "advertisers?ID=advertiser"
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  let updatedFilterData: any
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaFilterSearchFlag, tcpaNameError, searchFilterData, verticalFilter, filterSearchTcpaData } = tcpaCampSlice
  const [reload, setReload] = useState(false);
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const { verticalList, loading, error } = useAppSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  useEffect(() => {
    // dispatch(resetTcpaState(''))
    dispatch(setPayloadTcpaId(''))
    dispatch(setTcpaFlag(false));
    dispatch(setTcpafilterSearchFlag(false))
    setIsMouseOverDelete(false);
    // dispatch(setSearchFilterData([]));
    if (!tcpaSearchInput && searchFilterData.length === 0 && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      fetchInitialData();
      setDebouncedInputValue('');
      fetchCompApiData(dispatch);
    } else {

      if ((searchFilterData.length !== 0 && tcpaSearchInput === '') || (verticalFilter.length !== 0 && tcpaSearchInput === '')) {
        dispatch(setTcpaSearchInput(''));
        const debounceTimer = setTimeout(async () => {
          const payload = {
            "advertiserId": searchFilterData,
            "vertical": verticalFilter,
          }
          // if (searchFilterData.length > 0) {
          //   await  filterAdvertiserSearch(payload, dispatch);
          //   FrontEndSearchInput && searchTable(FrontEndSearchInput);
          // }

          { (verticalFilter.length > 0 || searchFilterData.length > 0) && filterTcpaSearch(payload, dispatch) }

        }, 1000);

        // Clear the timer on every key press to restart the countdown
        return () => clearTimeout(debounceTimer);
      }
      else {
        const debounceTimer = setTimeout(() => {
          setDebouncedInputValue(tcpaSearchInput);
        }, 600);
        // Clear the timer on every key press to restart the countdown
        return () => {
          dispatch(resetState("test"));
          clearTimeout(debounceTimer);
        };

      }
    }
  }, [dispatch, tcpaSearchInput, searchFilterData, reload, verticalFilter]);

  useEffect(() => {
    dispatch(fetchVerticals());
    dispatch(resetverticalFilter())
    dispatch(resetSearchFilterData())
    dispatch(setTcpaSearchInput(''));
    dispatch(setSearchFilterData([]));
    dispatch(fetchTcpaAction(advAPIPath, { ID: "advertiser" }))
    fetchMBrandApiData(dispatch);
    dispatch(setTcpaCreateJson([]));
  }, [reload])


  const fetchInitialData = () => {
    fetchMBrandApiData(dispatch);
    fetchTcpaCampaignData(dispatch, null);
    dispatch(setScrollNext(true));
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setTcpaFlag(true));
      fetchTcpaCampaignData(dispatch, currentCursor);
    }
  };

  const refresh = () => {
    setReload(!reload)
  }
  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTcpaSearchInput(event.target.value));
  }

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      dispatch(setSearchFilterData([]));
      fetchTcpaSearchResult(debouncedInputValue, dispatch)
    }
  }, [debouncedInputValue])

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchTcpaData.filter(function (item: any) {
        return (
          item.tcpaCampaign_id?.toString().toLowerCase().includes(userInput) ||
          item.tcpaCampaign_name?.toLowerCase().includes(userInput) ||
          item.Adv_ID?.toString().toLowerCase().includes(userInput) ||
          item.Advertiser_Name?.toLowerCase().includes(userInput) ||
          item.Status?.toString().toLocaleLowerCase().includes(userInput)
        );
      });

      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setTcpaData(filteredDataa));
    } else {
      dispatch(setTcpaData(filterSearchTcpaData));
      dispatch(setNoDataFlag(false));
    }
  }
  const hadleEditIcon = async (tcpa_camp_id: string) => {
    console.log("tesrt")
    try {
      // setIsLoadingData(true);
      const selectedTcpaCampaigns = tcpaFullData.filter((camp: any) => {
        return camp.tcpaCampaignId === tcpa_camp_id;
      });
      dispatch(setTcpaEditJson(selectedTcpaCampaigns[0]))
      dispatch(setTcpaCampaignIdOnEdit(selectedTcpaCampaigns[0].tcpaCampaignId))
      dispatch(setTcpaEditFlag(true));
      dispatch(updateStateForTCPAEditAction(selectedTcpaCampaigns[0]));
    } catch (error) {
      console.error("Error fetching data for editing", error);
    }
    // finally {
    //   // setIsLoadingData(false);
    // }
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterSearchInput(value);
    // dispatch(setFrontEndSearchInput(value));
    searchTable(value)
  }

  const handleFilterSearch = () => {
    dispatch(setTcpaSearchInput(''));
  }

  const handleChipRemove = (advertiserID: number) => {
    updatedFilterData = searchFilterData.filter((id: number) => id !== advertiserID);
    dispatch(setSearchFilterData(updatedFilterData));
  };

  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];


  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const proceedToLink = (selectedOption: string) => {
    closePopup();
    navigate('/createTcpaCamp')
    // if (selectedOption === 'EDU') {
    //   navigate('/createTcpaCamp')
    // } else if (selectedOption === 'AUTO') {
    //   navigate('/createTcpaCamp')
    // }
  };
  const handleFieldChange = (field: keyof typeof tcpaCampSlice, value: any) => {
    dispatch(setFields({ field, value }));
  };
  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter); // Update state
    setVerticalMouseOverDelete(false);
  };
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">TCPA</Typography>
            <Typography className="card-sub-heading">
              TCPA campaigns
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={tcpaFilterSearchFlag ? filterSearchInput : tcpaSearchInput}
              searchHandler={tcpaFilterSearchFlag ? handleFilter : handelSearch}
            />
            {/* <Link to='/createTcpaCamp' style={{ textDecoration: "none" }}>
              <Button variant="contained" startIcon={<AddIcon />} onClick={()=> setTcpaEditFlag(false)} sx={{ backgroundColor: "#0ae" }} >
                Create TCPA Campaign
              </Button>
            </Link> */}
            {location.pathname === "/TCPA-campaigns" &&
              <Button onClick={openPopup} variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: "#0ae" }} >
                Create TCPA Campaign
              </Button>}
            <CreateCampaignPopup
              isOpen={isPopupOpen}
              onClose={closePopup}
              onProceed={proceedToLink}
            />
          </Box>
        </Box>
        <Box display="flex" gap="8px"  >
          <FormControl className='campaign-vertical-select'>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch();
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 1;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;
                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                )

              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className='campaign-pub-select'>
            <InputLabel style={{ paddingLeft: '15px' }}>Filter by Advertiser</InputLabel>
            <Select
              multiple
              label="Filter by Advertiser"
              value={searchFilterData}
              onChange={(event) => {
                handleFilterSearch();
                dispatch(setSearchFilterData(event.target.value as []));
              }}
              renderValue={(selected) => {
                const maxVisibleChips = 2; // Maximum chips to display
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {fullData
                      .filter((adv: any) => displayed.includes(adv.advertiserID))
                      .map((adv: any) => (
                        <Chip
                          key={adv.SORTID}
                          label={`${adv.advertiserName}`}
                          onDelete={() => handleChipRemove(adv.advertiserID)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onClick={() => handleChipRemove(adv.advertiserID)}
                              onMouseEnter={() => setIsMouseOverDelete(true)}
                              onMouseLeave={() => setIsMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      ))}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={isMouseOverDelete}
              style={{ height: "50px" }}
            >
              {fullData
                .filter((adv: any) => adv.advertiserName)
                .sort((a: any, b: any) => a.advertiserName.localeCompare(b.advertiserName))
                .map((adv: any) => (
                  <MenuItem key={adv.advertiserID} value={adv.advertiserID}>
                    <Checkbox checked={searchFilterData.includes(adv.advertiserID)} />
                    <ListItemText primary={`${adv.advertiserName} - ${adv.advertiserID}`} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable
          tableData={tcpaTableData}
          tableHeadCell={tcpaHeadCell}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
          editComponentNavigation="/editTcpaCamp"
          editHandler={hadleEditIcon}
          reload={refresh}
        />
      </Paper>
    </React.Fragment>
  )
}
export default TCPAcampaigns

export const TcpaActionIcons = (props: any) => {
  const { tcpaCampData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const navigate = useNavigate();
  const fetchData = () => {
    fetchTcpaCampaignData(dispatch, null);
  };
  const rowData = tcpaCampData;
  const delPayload = tcpaCampData.tcpaCampaign_id;

  return (
    <>
      <Tooltip title="Edit">
        <EditIcon onClick={() =>
        //  setOpenPopup(true)
        {
          props?.editHandler(tcpaCampData?.tcpaCampaign_id)
          navigate("/editTcpaCamp", { state: { tcpaCampaignId: tcpaCampData?.tcpaCampaign_id } })
        }

        } color="primary" />

      </Tooltip>
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>

      <UploadTCPAContent rowData={tcpaCampData} editHandler={props.editHandler} reload={props.setReload} />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData}
      />
    </>
  )
}
