import React, { useEffect, useMemo, useState } from 'react'
import { RootState } from '../../Store/index';
import { Box, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, Snackbar, Switch, TableCell, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import './AdvertiserList.css'
import { Button, Paper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import MMTable, { IOSSwitch } from '../../Component/Table/MMTable';
import { advAccountHeadCells, advRows } from '../../Component/Table/tableInterface';
import Search from '../../Component/Search/Search';
import { useAppDispatch, useAppSelector } from "../../Store/hooks"
import { setFlag, fetchBrandNames, setFullData, updateAdvertiserAsync, setIsoLoading, setSearchInput, setNoDataFlag, setScrollNext, setBlockScroll, setSearchFilterData, fetchBrandNamesNew, fetchAllVendorData, setData } from '../../Store/Slices/advertiserTabApisData';
import { setAdvertiserField, resetForm, toggleStatus, togglePayPerLead, toggleApplicationFlag, toggleEnrollmentFlag, toggleLeadFlag, toggleStartFlag, setEditFlag } from '../../Store/Slices/CreateAdvButtonDialogSlice';
import { fetchAdvertAccApiData, fetchCompApiData, filterByVerticalSearch, searchAdvAccount } from '../../Services/advertiserTabApis';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIconPop } from './Action';
import { createAdvertiserAsync } from '../../Store/Slices/advertiserTabApisData';
import CloseIcon from '@mui/icons-material/Close';
import { getDisplayName } from '../User Accounts/Vendors/Vendors';
import { useLocation } from 'react-router-dom';
import { setfilterSearchFlag } from '../../Store/Slices/PublisherTabApisData';
import { useSelector } from 'react-redux';
import { fetchVerticals } from '../../Services/verticalList';

const Account = () => {
  return (
    <AccountMainSection />
  )
}
export default Account


const AccountMainSection = () => {

  const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('')
  const location = useLocation();
  const dispatch = useAppDispatch();
  const advertiserTableData = useAppSelector((state) => state.advertiserAccApi.data);
  const compApiData = useAppSelector((state) => state.advertiserAccApi.compApiData);
  const isLoading = useAppSelector((state) => state.advertiserAccApi.loading);
  const currentCursor = useAppSelector((state) => state.advertiserAccApi.currentCursor);
  const hasMoreData = useAppSelector((state) => state.advertiserAccApi.hasMoreData);
  const websiteOptions = useAppSelector((state: RootState) => state.advertiserAccApi);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const filterSearchFlag = useAppSelector((state) => state.publisherCampApi.filterSearchFlag);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  const advCampaignState = useAppSelector((state) => state.advertiserCampApi);
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  const { filterSearchAdvData } = advCampaignState
  const handleFieldChange = (field: keyof typeof advertiserState, value: any) => {
    dispatch(setAdvertiserField({ field, value }));
  };
  // console.log('websiteOptions====',websiteOptions)
  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setfilterSearchFlag(false))
    if (!searchInput && advertiserState.verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      fetchInitialData();
      fetchCompApiData(dispatch);
      dispatch(setNoDataFlag(false));
    } else if (advertiserState.verticalFilter.length !== 0 && searchInput === '') {
      dispatch(setSearchInput(''));
      const debounceTimer = setTimeout(async () => {
        const payload = {
          "vertical": advertiserState.verticalFilter
        }
        advertiserState.verticalFilter.length > 0 && filterByVerticalSearch(payload, dispatch);

      }, 1000);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }
    else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput, advertiserState.verticalFilter]);


  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(setSearchFilterData([]));
    // dispatch(setSearchInput(''));

    fetchCompApiData(dispatch);
  }, [])

  useEffect(() => {
    // dispatch(fetchBrandNames());
    // fetchVendor()
    dispatch(fetchAllVendorData())


  }, [dispatch]);


  const fetchInitialData = () => {
    fetchAdvertAccApiData(dispatch, null, location).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchAdvertAccApiData(dispatch, currentCursor, location);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchAdvData.filter(function (item: any) {
        return (
          item.Adv_ID?.toString().toLowerCase().includes(userInput) ||
          item.Advertiser_Name?.toLowerCase().includes(userInput) ||
          item.vertical?.toLowerCase().includes(userInput) ||
          item.vendorName?.toLowerCase().includes(userInput) ||
          item.Brand_Name?.toLowerCase().includes(userInput) ||
          item.Status?.toString().toLocaleLowerCase().includes(userInput)
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      // console.log('filteredDataa',filteredDataa)
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setData(filteredDataa));
    } else {
      dispatch(setData(filterSearchAdvData));
      // dispatch(appendCampData(filteredData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterSearchInput(value);
    // dispatch(setFrontEndSearchInput(value));
    searchTable(value)
  }


  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("event", event.target.value)
    // setSearchInput(event.target.value)
    dispatch(setSearchInput(event.target.value));
  }



  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      searchAdvAccount(debouncedInputValue, dispatch, 'advertiser', location)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    if (searchInput) {
      dispatch(setSearchInput(''));
    }
  }, []);

  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = advertiserState.verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };

  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }

  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">Advertiser List</Typography>
            <Typography className="card-sub-heading">
              Advertiser Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            {/* <Search value={searchInput} searchHandler={handelSearch} /> */}
            <Search value={filterSearchFlag ? filterSearchInput : searchInput} searchHandler={filterSearchFlag ? handleFilter : handelSearch} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ backgroundColor: "#0ae" }}
              onClick={() => { setOpenPopup(true); dispatch(setEditFlag(false)) }} >
              Create Advertiser
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={advertiserState.verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch();
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={advertiserState.verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable
          tableData={advertiserTableData}
          tableHeadCell={advAccountHeadCells}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
        />
      </Paper>
      <CreateAdver openPopup={openPopup} setOpenPopup={setOpenPopup} websiteOptions={websiteOptions}
        fetchInitialData={fetchInitialData} />
    </React.Fragment>
  )
}
const CreateAdver = (props: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const brandByVertical = useAppSelector((state) => state.advertiserAccApi.brandByVertical);
  const { openPopup, setOpenPopup, websiteOptions, fetchInitialData, rowData } = props;
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [advertiserNameError, setAdvertiserNameError] = useState(false);
  const [brandNameError, setbrandNameError] = useState(false);
  const [vendorNameError, setVendorNameError] = useState(false);
  const [verticalNameError, setVerticalNameError] = useState(false);
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    verticalID: item.verticalID,
    verticalName: item.verticalName,
  }));

  // const 
  const fullData = useAppSelector((state) => state.advertiserAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  // const handleFieldChange = (field: keyof typeof advertiserState, value: string | boolean) => {
  //   dispatch(setAdvertiserField({ field, value }));
  //   if (field === 'vertical') {
  //     dispatch(fetchBrandNamesNew(value));
  //   }

  // };
  const handleFieldChange = (field: keyof typeof advertiserState, value: any) => {
    if (field === 'vertical') {
      const selectedVertical: any = verticalOptions.find((v) => v.verticalID === value);
      if (selectedVertical) {
        dispatch(setAdvertiserField({ field, value: selectedVertical }));
        dispatch(fetchBrandNamesNew(selectedVertical.verticalID));
      }
    } else {
      dispatch(setAdvertiserField({ field, value })); // Update other fields as usual
    }
  };


  const cancelButton = () => {
    setOpenPopup(false);
    dispatch(resetForm());
    setAdvertiserNameError(false);
    setbrandNameError(false);
    setVendorNameError(false);
    setVerticalNameError(false);
  };

  const isAdvertiserNameExists = fullData.some(
    (data: any) => data.advertiserName?.trim() === advertiserState.GroupName?.trim()
  );

  const handleCreateAdvBu = async () => {
    setSuccessMessage('');
    setSuccessMessage('');
    setErrorMessage('');
    if (!advertiserState.GroupName.trim() || !advertiserState.brandName.trim() || !advertiserState.vendorName.trim() || !advertiserState.vertical) {
      setAdvertiserNameError(!advertiserState.GroupName.trim());
      setbrandNameError(!advertiserState.brandName.trim());
      setVerticalNameError(!advertiserState.vertical);
      setVendorNameError(!advertiserState.vendorName.trim());
    } else if (advertiserState.GroupName.includes(',')) {
      setErrorMessage('Comma is not allowed in Advertiser Name');
      setSnackbarOpen(true);
      return;
    } else {
      if (advertiserState.GroupName.includes(',')) {
        setErrorMessage('Comma is not allowed in Advertiser Name');
        setSnackbarOpen(true);
        return;
      }
      if (advertiserState.GroupName.toLowerCase().includes('vendor')) {
        setErrorMessage('Vendor name  is not allowed in Advertiser Name');
        setSnackbarOpen(true);
        return;
      }


      if (isAdvertiserNameExists && advertiserState.editFlag == false) {
        setErrorMessage('Advertiser with this name already exists');
        setSnackbarOpen(true);
        return;
      }
      setOpenPopup(false);
      dispatch(setIsoLoading(true));
      setAdvertiserNameError(false);
      setbrandNameError(false);
      setVendorNameError(false)
      setVerticalNameError(false);

      const payload = {
        GroupName: advertiserState.GroupName,
        brand: advertiserState.brandName,
        status: advertiserState.status,
        type: 'Advertiser',
        vendorName: advertiserState.vendorName,
        vertical: advertiserState.vertical,
        values: {
          address: advertiserState.advertiser,
          advertiser: advertiserState.advertiser,
          applicationFlag: advertiserState.applicationFlag,
          city: advertiserState.city,
          companyDescription: advertiserState.companyDescription,
          companyName: advertiserState.companyName,
          enrollmentFlag: advertiserState.enrollmentFlag,
          leadFlag: advertiserState.leadFlag,
          phNo: advertiserState.phNo,
          ppl: advertiserState.ppl,
          primaryContact: advertiserState.primaryContact,
          rpl: advertiserState.rpl,
          startsFlag: advertiserState.startsFlag,
          state: advertiserState.state,
          brand: advertiserState.brandName,
        },
      };

      let requestData
      if (rowData) {
        requestData = {
          ID: rowData.ID,
          SORTID: rowData.SORTID,
          createdOn: rowData.createdOn,
          GroupName: advertiserState.GroupName,
          mPubID: rowData.advertiserID,
          status: advertiserState.status,
          updatedOn: rowData.updatedOn,
          type: 'Advertiser',
          brand: advertiserState.brandName,
          vendorName: advertiserState.vendorName,
          vertical: advertiserState.vertical,
          values: {
            address: advertiserState.advertiser,
            advertiser: advertiserState.advertiser,
            applicationFlag: advertiserState.applicationFlag,
            city: advertiserState.city,
            companyDescription: advertiserState.companyDescription,
            companyName: advertiserState.companyName,
            enrollmentFlag: advertiserState.enrollmentFlag,
            leadFlag: advertiserState.leadFlag,
            phNo: advertiserState.phNo,
            ppl: advertiserState.ppl,
            primaryContact: advertiserState.primaryContact,
            rpl: advertiserState.rpl,
            startsFlag: advertiserState.startsFlag,
            state: advertiserState.state,
            website: rowData.values.website,
            brand: advertiserState.brandName,
          },
        };
      }

      try {

        if (advertiserState.editFlag) {
          try {
            // For editing an existing record, send a PUT request with the ID
            await dispatch(updateAdvertiserAsync(requestData));
            setSuccessMessage('Advertiser Updated Successfully')
          } catch (error) {
            console.error('Error updating advertiser:', error);
          }
        } else {
          try {
            // For creating a new record, send a POST request
            const response = await dispatch(createAdvertiserAsync(payload));
            response && setSuccessMessage('Advertiser Created Successfully');
          } catch (error) {
            console.error('Error Creating advertiser:', error);
          }

        }

        dispatch(setFlag(false));
        setSnackbarOpen(true);
        // fetchInitialData();
        fetchCompApiData(dispatch);
        if (searchInput !== '' && searchInput !== null) {
          searchAdvAccount(searchInput, dispatch, 'advertiser', location)
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create advertiser');
        setSnackbarOpen(false);
      }
      dispatch(resetForm());
      dispatch(setIsoLoading(false));
      // dispatch(setSearchInput(''));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const brandNameOptions = useMemo(() => {
    if (advertiserState.editFlag) {
      return [
        {
          id: null,
          name: advertiserState.brandName,
        },
      ];
    }

    const selectedVertical = advertiserState.vertical.verticalID;
    const filteredBrandNames = brandByVertical?.filter(
      (brand: any) => brand?.vertical.verticalID === selectedVertical
    );

    return filteredBrandNames
      ?.sort((a, b) => a.mBrandName.localeCompare(b.mBrandName))
      .map((brand: any) => ({
        id: brand.id,
        name: brand.mBrandName,
      }));
  }, [brandByVertical, advertiserState.vertical, advertiserState.editFlag, advertiserState.brandName]);


  const vendorNameOptions = useMemo(() => {
    return websiteOptions.vendorList.map((item: any, index: any) => (
      <MenuItem key={index} value={item.GroupName}>
        {getDisplayName(item.GroupName)}
      </MenuItem>
    ));
  }, [websiteOptions.vendorList]);
  const isSmallScreen = useMediaQuery('(max-width:900px)');


  return (
    <div>
      <Dialog open={openPopup} className='backdrop' fullWidth maxWidth="md">
        <DialogTitle>{advertiserState.editFlag ? "Update" : 'Create'} Advertiser</DialogTitle>
        <div className="close-button" onClick={cancelButton}><CloseIcon style={{ fontSize: '32px' }} /></div>
        <DialogContent style={{ paddingTop: "5px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isSmallScreen ? 12 : 7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ marginBottom: '8px' }}
                    disabled={advertiserState.editFlag}
                    label="Advertiser Name*" fullWidth
                    value={advertiserState.GroupName}
                    onChange={(e) => {
                      setAdvertiserNameError(false);
                      handleFieldChange('GroupName', e.target.value)
                    }}
                    error={advertiserNameError}
                    helperText={advertiserNameError ? 'Advertiser Name is mandatory' : ''}
                    FormHelperTextProps={{
                      sx: {
                        marginTop: '-3px',
                        marginLeft: '10px !important' // Adjust this value to shift the helper text right
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth >
                    <InputLabel>
                      Select Vertical*
                    </InputLabel>
                    <Select
                      label="Select Vertical*"
                      disabled={advertiserState.editFlag}
                      fullWidth
                      value={advertiserState.vertical.verticalID}
                      onChange={(e) => {
                        setVerticalNameError(false);
                        handleFieldChange("vertical", e.target.value);
                      }}
                      error={verticalNameError}
                    >
                      {/* Dynamically render MenuItems based on verticalOptions */}
                      {verticalOptions.map((vertical) => (
                        <MenuItem key={vertical.verticalID} value={vertical.verticalID}>
                          {vertical.verticalName}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  {verticalNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>Please select Vertical</div>}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth >
                    <InputLabel>
                      Select Brand*
                    </InputLabel>
                    <Select
                      label="Select Brand*"
                      fullWidth
                      disabled={!advertiserState.vertical || advertiserState.editFlag}
                      value={advertiserState.brandName || ''}
                      onChange={(e) => {
                        setbrandNameError(false);
                        handleFieldChange('brandName', e.target.value);
                      }}
                      error={brandNameError}
                    >
                      {brandNameOptions.map((brand: any) => (
                        <MenuItem key={brand.name} value={brand.name}>
                          {brand.name}
                        </MenuItem>
                      ))}
                    </Select>


                  </FormControl>
                  {brandNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>Please select Brand</div>}
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth >
                    <InputLabel>
                      Select Vendor*
                    </InputLabel>
                    <Select
                      label='Select Vendor*'
                      // sx={{marginTop:'10px'}}
                      fullWidth
                      value={advertiserState.vendorName}
                      onChange={(e) => {
                        setVendorNameError(false);
                        handleFieldChange('vendorName', e.target.value)
                      }}
                      // className={(advertiserState.vendorName === '') ? "vendor-select" : ''}
                      error={vendorNameError}
                    >
                      {vendorNameOptions}
                    </Select>
                  </FormControl>
                  {vendorNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>Please select Vendor</div>}
                </Grid>

                <Grid item xs={12}>
                  <TextField label="Advertiser Address" fullWidth variant="outlined" rows={3} multiline
                    value={advertiserState.advertiser}
                    onChange={(e) => handleFieldChange('advertiser', e.target.value)} />
                </Grid>
                {/* <Grid item xs={6}>
              <TextField label="Advertiser Description" fullWidth variant="outlined" rows={4} multiline
                value={advertiserState.companyDescription}
                onChange={(e) => handleFieldChange('companyDescription', e.target.value)} />
            </Grid>
            */}

                {/*              
            <Grid item xs={6}>
              <TextField label="Contact Person Name" fullWidth
                value={advertiserState.primaryContact}
                onChange={(e) => handleFieldChange('primaryContact', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="City" fullWidth
                value={advertiserState.city}
                onChange={(e) => handleFieldChange('city', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Phone Number" fullWidth
                value={advertiserState.phNo}
                onChange={(e) => handleFieldChange('phNo', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="State" fullWidth
                value={advertiserState.state}
                onChange={(e) => handleFieldChange('state', e.target.value)} />
            </Grid> */}
                <Grid item xs={12}>
                  <TextField label="RPL" fullWidth
                    value={advertiserState.rpl}
                    onChange={(e) => handleFieldChange('rpl', e.target.value)}
                    disabled={!advertiserState.ppl}
                    InputProps={{
                      style: {
                        backgroundColor: !advertiserState.ppl ? '#f2f2f2' : 'inherit', // Set the background color when disabled
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={isSmallScreen ? 12 : 5}>
              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography sx={{ textAlign: 'center' }} variant="h6" gutterBottom>
                  Flags and Status
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={advertiserState.status === 'Active'} onChange={() => dispatch(toggleStatus())} />}
                      label="Status"
                      labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={advertiserState.ppl} onChange={() => dispatch(togglePayPerLead())} />}
                      label="Pay Per Lead"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={advertiserState.leadFlag} onChange={() => dispatch(toggleLeadFlag())} />}
                      label="Lead Flag"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={advertiserState.applicationFlag} onChange={() => dispatch(toggleApplicationFlag())} />}
                      label="Application Flag"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={advertiserState.enrollmentFlag} onChange={() => dispatch(toggleEnrollmentFlag())} />}
                      label="Enrollment Flag"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={advertiserState.startsFlag} onChange={() => dispatch(toggleStartFlag())} />}
                      label="Start Flag"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button onClick={cancelButton} style={{ color: 'red' }}>CANCEL</Button>
          <Button variant="contained" color="primary" onClick={handleCreateAdvBu}>{advertiserState.editFlag ? "UPDATE" : 'CREATE'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}


export const AdvActionIcons = React.memo((props: any) => {
  const location = useLocation();
  const { AdvID, fetchInitialData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.advertiserAccApi.fullData);
  const brandNameList = useAppSelector((state: RootState) => state.advertiserAccApi);
  const brandNameListNew = useAppSelector((state: RootState) => state.advertiserAccApi.brandNamesNew);
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const getRowDataById = (advId: string) => {
    return fullData.find((row: any) => row.advertiserID === advId);
  };
  const rowData = getRowDataById(AdvID);
  function setDefaultVendor(companyData: any, vendors: any) {
    let vendorName = companyData.vendorName
    if (!vendorName) {
      let defaultVendor = vendors.find((vendor: any) => vendor.GroupName === "Vendor-Media-Matchers");
      if (defaultVendor) {
        vendorName = defaultVendor.GroupName; // Set the vendorName to the default one
      }
    }
    return vendorName;
  }
  const editFetch = () => {
    if (advertiserState.editFlag && rowData) {
      const fieldsToUpdate = [
        'companyDescription',
        'advertiser',
        'primaryContact',
        'city',
        'phNo',
        'state',
        'vendorName',
        // 'rpl',
        // 'ppl',
        // 'leadFlag',
        // 'applicationFlag',
        // 'enrollmentFlag',
        // 'startsFlag'
      ];



      const defaultVendorName = setDefaultVendor(rowData, brandNameList.vendorList);
      fieldsToUpdate.forEach((field: any) => {
        dispatch(setAdvertiserField({ field, value: rowData.values?.[field] || '' }));
      });

      const booleanFields = [
        'ppl',
        'leadFlag',
        'applicationFlag',
        'enrollmentFlag',
        'startsFlag'
      ]
      booleanFields.forEach((field: any) => {
        dispatch(setAdvertiserField({ field, value: rowData?.values?.[field] || false }));
      });

      dispatch(setAdvertiserField({ field: 'rpl', value: rowData?.values?.rpl || '' }));
      dispatch(setAdvertiserField({ field: 'GroupName', value: rowData.advertiserName || '' }));
      dispatch(setAdvertiserField({ field: 'brandName', value: rowData.brand || '' }));
      dispatch(setAdvertiserField({ field: 'status', value: rowData.status || 'Inactive' }));
      dispatch(setAdvertiserField({ field: 'vendorName', value: defaultVendorName }));
      dispatch(setAdvertiserField({ field: 'vertical', value: rowData?.vertical || '' }));

    }
  };

  const handleEditButtonClick = () => {
    editFetch();
    setOpenPopup(true);
  };

  const fetchData = () => {
    fetchAdvertAccApiData(dispatch, null, location);
    fetchCompApiData(dispatch);
  };


  let delPayload: { ID: any; mPubID: any; }
  delPayload = {
    ID: rowData?.ID,
    mPubID: rowData?.advertiserID,
  }


  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
      {/* <DeleteIcon onClick={() => console.log("delete ", AdvID,rowData, fullData)} color="error" /> */}
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
      <CreateAdver openPopup={openPopup} setOpenPopup={setOpenPopup}
        websiteOptions={brandNameList} rowData={rowData} fetchInitialData={fetchInitialData}
      />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData} />
    </div>
  )
});





