import React, { FC, useEffect, useMemo, useState } from 'react';
import PublisherStagingJSON from '../../Mock/PublishersStaging.json';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MMTable from '../../Component/Table/MMTable';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { setData, setFlag } from '../../Store/Slices/PublisherTabApisData';
import {
  setNoDataFlag,
  setScrollNext,
  setSearchFilterData,
  setSearchInput,
} from '../../Store/Slices/advertiserTabApisData';
import Search from '../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import { Editor, SelectionState } from 'react-draft-wysiwyg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier,
} from 'draft-js';
import { fetchCompApiData } from '../../Services/publisherTabApis';
import { generateHeader } from '../../service/Auth';
import axios from 'axios';
import { baseURLConfig } from '../../config/envConfig';
import { fetchAllPublisherCampaignsData } from '../../service/Publisher';

interface ReportHeadCell {
  numeric: boolean;
  id: string;
  label: string;
  key: string;
}

const frequencyType = [
  { id: 1, label: 'Previous Day' },
  { id: 2, label: 'Last 7 Days' },
  { id: 3, label: 'Last 30 Days' },
  { id: 4, label: 'Month to Date' },
];
const reportHeadCell: any = [
  {
    numeric: false,
    id: 'reportName',
    label: 'Report Name',
    key: 'reportName',
  },
  {
    numeric: false,
    id: 'reportType',
    label: 'Report Type',
    key: 'reportType',
  },
  {
    numeric: false,
    id: 'publisher',
    label: 'publisher',
    key: 'publisher',
  },
  {
    numeric: false,
    id: 'vertical',
    label: 'Vertical',
    key: 'vertical',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    key: '',
  },
];

const validationSchema = Yup.object().shape({
  reportName: Yup.string().required('Report Name is required'),
  reportType: Yup.string().required('Report Type is required'),
  verticalID: Yup.string().required('Vertical is required'),
  publisher: Yup.string().required('Publisher is required'),
  campaign: Yup.string().required('Campaign is required'),
  subject: Yup.string().required('Subject is required'),
  description: Yup.string().required('Description is required'),
  frequency: Yup.string().required('Frequency is required'),
  emails: Yup.array()
    .of(
      Yup.string().email('Invalid email format').required('Email is required')
    )
    .min(1, 'At least one email is required'),
});

const CreateReport: React.FC<any> = props => {
  const { openPopup, setOpenPopup, verticalList } = props;
  const [emailList, setEmailList] = useState<any>([]);
  const [emailInput, setEmailInput] = useState<any>('');
  const [publisherList, setPublisherList] = useState<any>([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [campaignData, setCampaignData] = useState<any>([]);
  const formik: any = useFormik<any>({
    initialValues: {
      reportName: '',
      verticalID: '',
      emails: [],
      publisher: '',
      campaign: '',
      reportType: '',
      subject: '',
      frequency: '',
    },
    onSubmit: values => {
      console.log('submit', values);
    },
    validationSchema,
  });

  // Validate email format
  const isValidEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Remove an email from the list
  const handleRemoveEmail = (emailToRemove: any) => {
    const newEmailList = emailList.filter(
      (email: any) => email !== emailToRemove
    );
    setEmailList(newEmailList);
    formik.setFieldValue('emails', newEmailList);
  };

  // Handle adding an email when the "Enter" key is pressed
  const handleAddEmail = (event: any) => {
    if (event.key === 'Enter' && isValidEmail(emailInput)) {
      setEmailList([...emailList, emailInput]);
      setEmailInput('');
      formik.setFieldValue('emails', [...emailList, emailInput]);
    }
  };

  const handleCancel = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    rawContentState?.blocks.forEach(value => {
      if (value.text) {
        console.log(draftToHtml(rawContentState));
      }
    });

    formik.setFieldValue('description', draftToHtml(rawContentState));
  }, [editorState]);

  const CustomOption: FC<any> = ({ editorState, onChange }) => {
    const removeBackGroundColor = () => {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      let htmlContent = draftToHtml(rawContentState);
      htmlContent = htmlContent.replace(
        /(style="[^"]*)background-color:\s*[^;"]*;?([^"]*")/g,
        '$1$2'
      );
      const contentBlock = htmlToDraft(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
    };

    return (
      <IconButton
        sx={{
          border: '1px solid #F1F1F1',
          borderRadius: '2px',
          height: '100%',
        }}
        onClick={removeBackGroundColor}
      >
        <InvertColorsOffIcon sx={{ fontSize: '14px' }} />
      </IconButton>
    );
  };

  const fetchData = async (pubID: string) => {
    const arg2 = {
      path: 'publishers/campaign',
      queryParamsObject: {
        publisherID: pubID,
        verticalID: formik?.values?.verticalID,
      },
    };
    const response = await fetchAllPublisherCampaignsData(
      arg2.path,
      arg2.queryParamsObject
    );

    const filteredData = response?.data?.map((item: any) => {
      const publisherName = item?.publisherName;
      const formattedPublisherName = publisherName
        ? publisherName.replace(/^PUB-|-ADM-\d+$/g, '')
        : '';

      return {
        Status: item.status,
        campaign_id: item?.SORTID,
        campaign_name: item?.campaignname,
        Publisher_ID: item?.publisherID,
        Publisher_Name: item?.values?.companyName
          ? item?.values?.companyName
          : item?.publisher?.GroupName,
        vertical: item?.vertical,
      };
    });
    setCampaignData(filteredData);
    return response.data;
  };

  const getCampaignsData = (pubId: any) => {
    fetchData(pubId);
    // filterPublisherSearch({ mPubIds: [pubId] });
  };

  useEffect(() => {
    console.log('campaignData', formik?.errors, verticalList);
  }, [formik?.errors]);

  const fetchPublishersDataByVertical = async (paylode: any) => {
    const headers = await generateHeader();
    const response = await axios.post(
      `${baseURLConfig.baseURl}/filterByVertical?ID=publisher`,
      paylode,
      { headers }
    );
    return response;
  };

  const handleVerticalChange = async (id: any) => {
    const pubLists = await fetchPublishersDataByVertical({
      vertical: [`${id}`],
    });

    const pubData = pubLists?.data?.result?.data?.map((pub: any) => ({
      label: `${pub.publisherName || pub.GroupName}`,
      value: `${pub.mPubID}`,
    }));

    // console.log("PUBDATAS::::::::::::::::::::::::::::", pubDatas)
    pubData?.sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase(); // Ignore upper and lowercase
      const labelB = b.label.toUpperCase(); // Ignore upper and lowercase
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }

      // labels must be equal
      return 0;
    });

    setPublisherList(pubData);
  };

  useEffect(() => {
    console.log('publisherList', publisherList);
  }, [publisherList]);

  return (
    <Dialog open={openPopup} onClose={handleCancel} fullWidth maxWidth="md">
      <DialogTitle>Email Reporting</DialogTitle>
      <div className="close-button" onClick={handleCancel}>
        <CloseIcon style={{ fontSize: '32px' }} />
      </div>
      <DialogContent className="Update-user">
        {' '}
        <Grid container spacing={2}>
          {' '}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                sx={{ background: '#ffffff' }}
                label="Report Name"
                name="reportName"
                value={formik?.values?.reportName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.reportName && Boolean(formik.errors.reportName)
                }
              />
            </FormControl>
            {formik.touched.reportName && formik.errors.reportName && (
              <Box color="#db2424" mt={1}>
                {formik.errors.reportName}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              required
              error={
                formik.touched.verticalID && Boolean(formik?.errors?.verticalID)
              }
            >
              <InputLabel>Select Vertical</InputLabel>
              <Select
                label="Select Vertical"
                value={formik?.values?.Vertical}
                name="verticalID"
                onChange={e => {
                  setPublisherList([]);
                  handleVerticalChange(e.target.value);
                  formik.handleChange(e);
                }}
                // error={verticalNameError}
              >
                {verticalList?.map((val: any) => (
                  <MenuItem key={val?.verticalID} value={val?.verticalID}>
                    {val?.verticalName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.verticalID && formik.errors.verticalID && (
              <Box color="#db2424" mt={1}>
                {formik.errors.verticalID}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              required
              error={formik.touched.publisher && formik.errors.publisher}
            >
              <InputLabel>Select Publisher</InputLabel>
              <Select
                label="Select Publisher"
                value={formik?.values?.publisher}
                name="publisher"
                onChange={e => {
                  // alert(e.target.value);
                  setCampaignData([]);
                  getCampaignsData(e.target.value);
                  formik.handleChange(e);
                }}
              >
                {publisherList?.map((pub: any) => (
                  <MenuItem key={pub.value} value={pub.value}>
                    {pub.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.publisher && formik.errors.publisher && (
              <Box color="#db2424" mt={1}>
                {formik.errors.publisher}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              required
              error={
                formik.touched.campaign && Boolean(formik?.errors?.campaign)
              }
            >
              <InputLabel>Select Campaigns</InputLabel>
              <Select
                label="Select Campaigns"
                value={formik?.values?.campaign}
                name="campaign"
                onChange={formik.handleChange}
              >
                {campaignData &&
                  campaignData.length > 0 &&
                  campaignData.map((val: any) => (
                    <MenuItem key={val.campaign_id} value={val.campaign_id}>
                      {val.campaign_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {formik.touched.campaign && formik.errors.campaign && (
              <Box color="#db2424" mt={1}>
                {formik.errors.campaign}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              required
              error={
                formik.touched.reportType && Boolean(formik.errors.reportType)
              }
            >
              <InputLabel>Report Type</InputLabel>
              <Select
                label="Report Type"
                value={formik?.values?.reportType}
                name="reportType"
                onChange={formik.handleChange}
              >
                <MenuItem value="Clicks Report">Clicks Report</MenuItem>
                <MenuItem value="SEM Report">SEM Report</MenuItem>
                <MenuItem value="Leads Report">Leads Report</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.reportType && formik.errors.reportType && (
              <Box color="#db2424" mt={1}>
                {formik.errors.reportType}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              required
              error={
                formik.touched.frequency && Boolean(formik?.errors?.frequency)
              }
            >
              <InputLabel>Select Frequency</InputLabel>
              <Select
                label="Select Frequency"
                value={formik?.values?.frequency}
                name="frequency"
                onChange={formik.handleChange}
              >
                {frequencyType?.map((val: any) => (
                  <MenuItem key={val.id} value={val.id}>
                    {val.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.frequency && formik.errors.frequency && (
              <Box color="#db2424" mt={1}>
                {formik.errors.frequency}
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              className="chips-container"
              sx={{
                borderColor:
                  formik.touched.emails && formik.errors.emails
                    ? '#d32f2f'
                    : '',
              }}
            >
              <label htmlFor="">Email Addresses *</label>
              <TextField
                placeholder="Enter Email Addresses"
                value={emailInput}
                onChange={e => setEmailInput(e.target.value)}
                onKeyDown={handleAddEmail}
                // error={formik.touched.emails && formik.errors.emails}
              />

              {emailList.length > 0 && (
                <Stack direction="row" flexWrap="wrap" gap={1} marginTop={2}>
                  {emailList.map((email: any, index: any) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => handleRemoveEmail(email)}
                    />
                  ))}
                </Stack>
              )}
            </Box>
            {formik.touched.emails && formik.errors.emails && (
              <Box color="#db2424" mt={1}>
                {formik.errors.emails}
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                sx={{ background: '#ffffff' }}
                label="Subject Line"
                value={formik?.values?.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="subject"
                error={formik.touched.subject && Boolean(formik.errors.subject)}
              />
            </FormControl>
            {formik.touched.subject && formik.errors.subject && (
              <Box color="#db2424" mt={1}>
                {formik.errors.subject}
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={(editorState: any) => {
                  setEditorState(editorState);
                  // Set the editor content to the formik field
                  // formik.setFieldValue(
                  //   'description',
                  //   editorState.getCurrentContent().getPlainText()
                  // );
                }}
                onChange={() => {}}
                onFocus={() => {}}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'emoji',
                    'remove',
                    'history',
                  ],
                }}
                toolbarCustomButtons={[<CustomOption />]}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} style={{ color: 'red' }}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EmailReporting = () => {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [verticalList, setVerticalList] = React.useState<any>([]);
  const [tableRow, setTableRow] = useState<any>([
    {
      reportName: 'Report 1',
      reportType: 'Clicks Report',
      publisher: 'pub1',
      vertical: 'EDU',
    },
    {
      reportName: 'Report 2',
      reportType: 'Led Report',
      publisher: 'pub2',
      vertical: '',
    },
  ]);
  const dispatch = useAppDispatch();
  // const { data, isLoading } = useAppSelector((state) => state.publisherTabApisData);

  const handleCreateReport = () => {
    // Add logic to create a new report
  };

  const fetchVerticals = async () => {
    try {
      const headers = await generateHeader();
      const response = await axios.get(
        `${baseURLConfig.baseURl}/getVerticalList`,
        { headers }
      );
      setVerticalList(response?.data?.data);

      // setVerticalResponse({
      //   data: response?.data?.data,
      //   isFetched: true,
      //   error: '',
      // });
    } catch (error) {
      const errorMessage =
        axios.isAxiosError(error) && error.response
          ? error.response.data.message || 'Failed to fetch verticals'
          : 'Network error';
    }
  };

  useEffect(() => {
    fetchVerticals();
    fetchCompApiData(dispatch);
  }, []);
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className="card-heading">Reporting </Typography>
            <Typography className="card-sub-heading">Email Reports</Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              Create Report
            </Button>
          </Box>
        </Box>
        <MMTable
          tableData={tableRow}
          tableHeadCell={reportHeadCell}
          // isLoadingMore={isLoading}
          fetchMoreData={() => {}}
          hasMoreData={false}
          fetchInitialData={() => {}}
          // editComponentNavigation="/mPartners-config"
          hasEdit
          onEdit={() => {}}
          hasDelete
          onDelete={() => {}}
        />
      </Paper>
      {openPopup && (
        <CreateReport
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          verticalList={verticalList}
        />
      )}
    </React.Fragment>
  );
};

export default EmailReporting;
