import { ThunkDispatch } from 'redux-thunk'; // Import the ThunkDispatch type
import { AnyAction } from '@reduxjs/toolkit';
import { setLoading, setCurrentCursor, setHasMoreData, appendData, setFullData, setApiFullData, appendCpcData, setApiFullCpcData, setFullCpcData, setfilterSearchFlag } from '../Store/Slices/PublisherTabApisData';
import axios from 'axios';
import { baseURLConfig } from '../config/envConfig';
import { fetchToken, generateHeader } from '../service/Auth';
import { setBlockScroll, setIsoLoading, setNoDataFlag } from '../Store/Slices/advertiserTabApisData';

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;
const baseURL = baseURLConfig.baseURl;

const publisherAccApiUrl = `${baseURL}/publishers?limit=25&ID=publisher`;
const API_BASE_URL = `${baseURL}/publishers`;



export const fetchMpartnerApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null,
  location: any,
) => {
  try {
    dispatch(setHasMoreData(true));
    dispatch(appendData([]));
    dispatch(setLoading(true));
    const idToken = await fetchToken()
    const headers = {
      'Authorization': idToken,
      'Content-Type': 'application/json',
    }

    let url = publisherAccApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url, { headers });
    const jsonData = await response.json();
    const filteredData = jsonData.result.data.map((item: any) => ({
      publisherID: item?.mPubID,
      Publisher_Name: item?.publisherName,
      Vertical_Count: item?.vertical.length,
      Publisher_Campaign_Count: item?.campaignCount,
      // Created_on: item.createdOn,
      // website: item?.website,
    }));
    // dispatch(appendFullData(jsonData.result.data))
    dispatch(appendData(filteredData));
    dispatch(setCurrentCursor(jsonData.result.meta.cursor));
    dispatch(setHasMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

//for serch functionality fetching complete data withour limit and cursor.
export const fetchMpartnerCompApiData = async (dispatch: DispatchType, location: any) => {
  try {
    const idToken = await fetchToken()
    const headers = {
      'Authorization': idToken,
      'Content-Type': 'application/json',
    }
    // const response = await fetch('https://wx1uyu8m94.execute-api.us-east-1.amazonaws.com/staging/v3/publishers?ID=publisher');
    const response = await fetch(`${baseURL}/publishers?ID=publisher`, { headers });
    const jsonData = await response.json();


    const compApiData = jsonData.result.data.map((item: any) => ({
      publisherID: item?.mPubID,
      publisherName: item?.publisherName,
      Vertical_Count: item?.vertical.length,
      Publisher_Campaign_Count: item?.campaignCount,
      // createdOn: item?.createdOn,
      // website: item?.website,
    }));
    dispatch(setApiFullData(compApiData));
    dispatch(setFullData(jsonData.result.data));
  } catch (error) {
    throw new Error('Failed to fetch mPartner Api data');
  }
};
export const fetchFilterMpartnerCompApiData = async (palyoad: Object, dispatch: DispatchType) => {
  dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  // const encodedValue: string = encodeURIComponent(inputValue);
  const headers = await generateHeader();
  const response = await axios.post(`${baseURL}/filterByVertical?ID=publisher`, palyoad, { headers });
  const compApiData = response.data.result.data.map((item: any) => ({
    publisherID: item?.mPubID,
    publisherName: item?.GroupName,
    Vertical_Count: item?.verticalCount,
    Publisher_Campaign_Count: item?.campaignCount,
  }));
  dispatch(appendData(compApiData));

  // dispatch(setApiFullData(compApiData));
  // dispatch(setFullData(response.data));
  if (palyoad && compApiData.length === 0) {
    dispatch(setNoDataFlag(true));
  } else {
    dispatch(setNoDataFlag(false));
    dispatch(setBlockScroll(true));
  }
  dispatch(setIsoLoading(false));
}

////////////for CPC THRESHOLD

export const fetchCpcApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null,
  location: any,
) => {
  try {
    dispatch(setHasMoreData(true));
    dispatch(appendCpcData([]));
    dispatch(setLoading(true));
    const idToken = await fetchToken()
    const headers = {
      'Authorization': idToken,
      'Content-Type': 'application/json',
    }

    let url = publisherAccApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url, { headers });
    const jsonData = await response.json();
    const filteredData = jsonData.result.data.map((item: any) => ({
      publisherID: item?.mPubID,
      Publisher_Name: item?.publisherName,
      Vertical_Count: item?.vertical.length
      // Created_on: item.createdOn,
    }));
    dispatch(appendCpcData(filteredData));
    dispatch(setCurrentCursor(jsonData.result.meta.cursor));
    dispatch(setHasMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};


//for serch functionality fetching complete data withour limit and cursor.
export const fetcCpcCompApiData = async (dispatch: DispatchType, location: any) => {
  try {
    const idToken = await fetchToken()
    const headers = {
      'Authorization': idToken,
      'Content-Type': 'application/json',
    }
    const response = await fetch(`${baseURL}/publishers?ID=publisher`, { headers });
    const jsonData = await response.json();
    const compApiData = jsonData.result.data.map((item: any) => ({
      publisherID: item?.mPubID,
      publisherName: item?.publisherName,
      Vertical_Count: item?.vertical?.length
      // createdOn: item?.createdOn,
    }));

    dispatch(setApiFullCpcData(compApiData));
    dispatch(setFullCpcData(jsonData.result.data));
  } catch (error) {
    throw new Error('Failed to fetch mPartner Api data');
  }
};

export const filterCpcCompApiData = async (palyoad: Object, dispatch: DispatchType) => {
  dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  // const encodedValue: string = encodeURIComponent(inputValue);
  const headers = await generateHeader();
  const response = await axios.post(`${baseURL}/filterByVertical?ID=publisher`, palyoad, { headers });
  const compApiData = response.data.result.data.map((item: any) => ({
    Publisher_ID: item?.mPubID,
    Publisher_Name: item?.GroupName,
    Vertical_Count: item?.verticalCount
  }));
  dispatch(appendCpcData(compApiData));

  // dispatch(setApiFullData(compApiData));
  // dispatch(setFullData(response.data));
  if (palyoad && compApiData.length === 0) {
    dispatch(setNoDataFlag(true));
  } else {
    dispatch(setNoDataFlag(false));
    dispatch(setBlockScroll(true));
  }
  dispatch(setIsoLoading(false));
}