import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Search from '../../../Component/Search/Search';
import MMTable from '../../../Component/Table/MMTable';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { fetchAdvertAccApiData, filterByVerticalSearch, searchAdvAccount } from '../../../Services/advertiserTabApis';
import {
  setBlockScroll,
  setFlag,
  setIsoLoading,
  setNoDataFlag,
  setScrollNext,
  setSearchInput,
} from '../../../Store/Slices/advertiserTabApisData';
import { generateHeader } from '../../../service/Auth';
import { baseURLConfig } from '../../../config/envConfig';
import { RootState } from '../../../Store';
import { setAdvertiserField, resetForm, resetFilters } from '../../../Store/Slices/CreateAdvButtonDialogSlice';
import { fetchVerticals } from '../../../Services/verticalList';
import { useSelector } from 'react-redux';


const advAccountHeadCells: any[] = [
  {
    numeric: true,
    id: 'Adv_ID',
    label: 'Adv ID',
    key: 'Adv_id',
  },
  {
    id: 'Advertiser_Name',
    numeric: false,
    key: 'advertiser_name',
    label: 'Advertiser Name',
  },
  // {
  //   id: "Brand_Name",
  //   numeric: false,
  //   key: "Brand_Name",
  //   label: "Brand Name",
  // },
  // {
  //   id: 'User',
  //   numeric: false,
  //   key:"User",
  //   label:"User",
  // },
  {
    id: 'vendorName',
    numeric: false,
    label: 'Vendor Name',
    key: '',
  },
  //  {
  //   id: 'Created_on',
  //   numeric: false,
  //   label: 'Created On',
  //   key: '',
  // },
  {
    id: 'vertical',
    numeric: false,
    label: 'Vertical',
    key: '',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    key: '',
  },
];

const advertiserTableData: any[] = [
  {
    advertiserID: '33997',
    advertiserName: 'All-Star Directories',
    vertical: "EDU"
    // createdOn: '11/6/2021 4:35:07 AM',
    // cam1: {},
    // cam2: {},
  },
];

const BudgetList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const { verticalFilter } = advertiserState
  const advertiserTableData = useAppSelector(
    (state: any) => state.advertiserAccApi.data
  );
  const isLoading = useAppSelector(
    (state: any) => state.advertiserAccApi.loading
  );
  const searchInput = useAppSelector(
    state => state.advertiserAccApi.searchInput
  );
  const hasMoreData = useAppSelector(
    state => state.advertiserAccApi.hasMoreData
  );
  const currentCursor = useAppSelector(
    state => state.advertiserAccApi.currentCursor
  );

  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const [tableDate, setTableData] = useState<any>([]);
  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchAdvertAccApiData(dispatch, currentCursor, location);
    }
  };
  const handleFieldChange = (field: keyof typeof advertiserState, value: any) => {
    dispatch(setAdvertiserField({ field, value }));
  };
  useEffect(() => {
    return () => {
      dispatch(setSearchInput(''));
      dispatch(resetFilters());
    };
  }, [dispatch, location]);
  useEffect(() => {
    dispatch(setFlag(false));
    if (!searchInput && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      fetchInitialData();
      dispatch(setNoDataFlag(false));
    } else if (verticalFilter.length !== 0 && searchInput === '') {
      dispatch(setSearchInput(''));
      const debounceTimer = setTimeout(async () => {
        const payload = {
          "vertical": verticalFilter
        }
        verticalFilter.length > 0 && filterByVerticalSearch(payload, dispatch);

      }, 1000);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }

    else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput, verticalFilter]);

  const fetchInitialData = () => {
    fetchAdvertAccApiData(dispatch, null, location).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  useEffect(() => {
    setTableData(
      advertiserTableData?.map(({ Status, Brand_Name, ...rest }: any) => rest)
    );
  }, [advertiserTableData]);

  const getCampanig = async (palyoad: Object) => {
    try {

      const headers = await generateHeader();
      const response = await axios.post(
        `${baseURLConfig.baseURl}/filter?campaignType=advertiserCampaign`,
        palyoad,
        { headers }
      );
      const filteredData = response?.data?.data?.map((item: any) => {
        const advertiserName = item?.publisherName;
        const formattedAdvertiserName = advertiserName
          ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '')
          : '';
        return {
          ID: item?.ID,
          SORTID: item?.SORTID,
          campaignname: item?.campaignname,
          budgetSettings: item?.budgetSettings,
          clickCapSettings: item?.clickCapSettings,
          // clickCapFilteringLimit: item?.clickCapFilteringLimit || false,
          // budgetFilteringLimit: item?.budgetFilteringLimit || false,
        };
      });
      return filteredData;
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("event", event.target.value)
    // setSearchInput(event.target.value)
    dispatch(setSearchInput(event.target.value));
  };

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      searchAdvAccount(debouncedInputValue, dispatch, 'advertiser', location)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(resetFilters());
    if (searchInput) {
      dispatch(setSearchInput(''));
    }
  }, []);
  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }
  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Advertiser List</Typography>
            <Typography className="card-sub-heading">
              Advertiser Summary
            </Typography>
          </Box>
          <Box
            display="flex"
            gap="16px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: '16px', md: '0' },
              alignItems: { xs: 'flex-start', md: 'center' },
            }}
          >
            <Search value={searchInput} searchHandler={handelSearch} />
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch()
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable
          tableData={tableDate}
          tableHeadCell={advAccountHeadCells}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
          hasEdit
          onEdit={async (val: any) => {
            dispatch(setIsoLoading(true));
            const campaign = await getCampanig({ mPubIds: [val?.Adv_ID] });
            dispatch(setIsoLoading(false));
            navigate(
              location.pathname === '/budget-allocation'
                ? '/edit-budget-caps'
                : '/edit-caps-filtering',
              {
                state: { advertiserData: val, campaignData: campaign },
              }
            );
          }}
        />
      </Paper>
    </React.Fragment>
  );
};

export default BudgetList;
