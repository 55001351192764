import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  InputAdornment,
  Input,
  Button,
  Paper,
  TextareaAutosize,
  FormGroup,
  IconButton,
  CardMedia,
  Tooltip,
  Card,
  OutlinedInput,
  Checkbox,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAlert from '@mui/material/Alert';
import { useFormik, FormikProvider, FieldArray, getIn } from 'formik';
import debounce from 'lodash/debounce';
import { uploadSEOToS3 } from '../../../../service/S3 Upload';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { fetchCompApiData } from '../../../../Services/advertiserTabApis';
import { collectTcpaPagesValue, createTcpaCampAsync, setPayloadTcpaId, setSnackbarMessage, setSnackbarOpen, setSnackbarType, setTcpaCampaignIdOnEdit, setTcpaCampaignName, setTcpaCreateJson, setTcpaEditJson, setTcpaNameError, storeSelectedTcpaAdvertiser, tcpaCampSlice, updateStateForTCPAEditAction } from '../../../../Store/Slices/tcpaCampSlice';
import axios from 'axios';
import { tcpaWebsiteInfoValidation } from '../../validations';
import { CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_UPDATE_SUCCESS } from '../../../../Mock/MessageContstant';


export const TCPA_Description: FC<any> = ({ submitRef, handleNext, mBrandData }) => {
  const dispatch = useAppDispatch();
  const [footerLogo, setFooterLogo] = useState<string>('');
  // const [uploadImage2, setUploadImage2] = useState<string>('');
  const [favicon, setFavicon] = useState<string>('');
  const [imageAlert1, setImageAlert1] = React.useState(false);
  const [imageExtAlert, setImageExtAlert] = React.useState(false);
  const [faviconExtAlert, setFaviconExtAlert] = React.useState(false);
  const [faviconAlert, setFaviconAlert] = React.useState(false);
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { payloadTcpaId, advertiserID, tcpaCreateJsonData, advertiserList, tcpaLogo, sortId, tcpaBrand, tcpaNameError, tcpaCampaignName, advertiserName, tcpaEditJsonData, tcpaEditFlag, tcpaCampaignIdOnEdit, tcpaVertical } = tcpaCampSlice


  interface tcpaWebsiteInfoField {
    mBrand: string;
    SORTID: string;
    advertiserName: string;
    headContent: string;
    postUrl: string;
    tcpaText: string;
    defaultProgram: string;
    defaultProgramValue: string;
    hiddenField: any;
    shortDescription: string;
    description: string;
    logo: string;
    bid: number;
    status: string;
    campaignType: string;
    // tcpaCampName:string;
    advertiserId: string;
    tcpaCheckbox: boolean;
    vertical: string;
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [tcpaNameError]);

  useEffect(() => {
    formik.setFieldValue('mBrand', tcpaBrand);
    formik.setFieldValue('logo', tcpaLogo);
    formik.setFieldValue('SORTID', sortId);
    // formik.setFieldValue('tcpaCampName', tcpaCampaignName);
    formik.setFieldValue('advertiserName', advertiserName);
    formik.setFieldValue('advertiserId', advertiserID);
  }, [tcpaBrand, tcpaLogo])
  const [TcpaCampNameError, setTcpaCampNameError] = useState(false);

  const formik = useFormik<tcpaWebsiteInfoField>({
    initialValues: {
      mBrand: '',
      advertiserName: '',
      advertiserId: '',
      // tcpaCampName:'',
      headContent: '',
      postUrl: '',
      tcpaText: '',
      defaultProgram: '',
      defaultProgramValue: '',
      hiddenField: [{ key: '', value: '' }],
      shortDescription: '',
      description: '',
      logo: '',
      bid: 0,
      status: 'InActive',
      campaignType: "tcpaCampaign",
      tcpaCheckbox: true,
      SORTID: '',
      vertical: tcpaVertical,
    },
    onSubmit: async values => {
      const payload = {
        ...values,
        tcpaCampName: tcpaCampaignName,
        ...(payloadTcpaId && { tcpaCampaignId: payloadTcpaId })
      }
      const payloadEdit = {
        ...values,
        tcpaCampName: tcpaCampaignName,
        tcpaCampaignId: tcpaCampaignIdOnEdit
      }
      const val: any = values
      if (!tcpaCampaignName.trim()) {
        //  dispatch(setTcpaNameError(!tcpaCampaignName.trim()))
        dispatch(setTcpaNameError(!tcpaCampaignName.trim()));
      } else {
        // console.log(values);

        try {
          if (tcpaEditFlag) {
            const response = await dispatch(createTcpaCampAsync({ payload: payloadEdit, method: 'put' })).unwrap();
            // console.log('response',response.data)
            if (response) {
              dispatch(setTcpaEditJson(response.data))
              dispatch(setSnackbarOpen(true))
              dispatch(setSnackbarType("success"))
              dispatch(setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS))
              handleNext()
            }
            if (response.error) {
              setSnackbarOpen(true)
              setSnackbarType("error")
              setSnackbarMessage(response.error?.message)
            }
            //  dispatch(setPayloadTcpaId(response.tcpaCampaignId))
          }
          else {
            const response = await dispatch(createTcpaCampAsync({ payload: payload, method: payloadTcpaId ? 'put' : 'post' })).unwrap();
            // console.log('response.data',response)
            if (response) {
              dispatch(setTcpaCreateJson(response))
              // dispatch(updateStateForTCPAEditAction(response.data))
              dispatch(setSnackbarOpen(true))
              dispatch(setSnackbarType("success"))
              dispatch(setSnackbarMessage(CAMPAIGN_CREATE_SUCCESS))
              !payloadTcpaId && dispatch(setPayloadTcpaId(response.tcpaCampaignId))
              handleNext();
            }
            if (response.error) {
              setSnackbarOpen(true)
              setSnackbarType("error")
              setSnackbarMessage(response.error?.message)
            }

          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    validationSchema: tcpaWebsiteInfoValidation,
  });
  // console.log('tcpaEditJsonData',tcpaEditJsonData)
  useEffect(() => {
    if (tcpaEditFlag) {
      formik.setValues(tcpaEditJsonData);
      dispatch(setTcpaCampaignName(tcpaEditJsonData?.tcpaCampName))
      //  dispatch(setTcpaCampaignIdOnEdit(tcpaEditJsonData[0]?.tcpaCampaignId))
    } else {
      tcpaCreateJsonData.advertiserId && formik.setValues(tcpaCreateJsonData);
      // console.log('hitted',tcpaCreateJsonData.advertiserId)
    }
  }, [tcpaEditFlag]);
  //  console.log(tcpaEditJsonData[0],formik.values,tcpaEditFlag)

  const handleCustomChange = (fieldName: string) => {
    formik.setFieldValue(fieldName, tcpaBrand);
    formik.setFieldValue('logo', tcpaLogo);
  };

  const debouncedSetFieldValue = useCallback(
    debounce((fieldName, value) => {
      formik.setFieldValue(fieldName, value);
    }, 10),
    [] // Empty dependency array ensures that the debounced function is memoized and reused
  );

  const handleUploadFavicon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFaviconAlert(false);
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];

    if (file) {
      if (file.type === 'image/x-icon' || file.type === 'image/svg+xml') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 32 && height <= 32) {
              const objectURL = uploadSEOToS3(
                reader.result,
                `${new Date().getTime()}`,
                file
              );
              objectURL.then((res: any) => {
                formik.setFieldValue('favIcon', res);
                setFavicon(res as any);
              });
            } else {
              event.target.value = '';
              // Display an error alert
              setFaviconAlert(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        handleUnsupportedFileType();
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }
  };

  const handlefooterLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageAlert1(false);
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];

    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 200 && height <= 90) {
              const objectURL = uploadSEOToS3(
                reader.result,
                `${new Date().getTime()}`,
                file
              );
              objectURL.then((res: any) => {
                formik.setFieldValue('footerLogo', res);
                // console.log('file details....', res);
                setFooterLogo(res as any);
              });
            } else {
              event.target.value = '';
              // Display an error alert
              setImageAlert1(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        handleUnsupportedImageFileType();
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }
  };
  const handleUnsupportedFileType = () => {
    setFaviconExtAlert(true);
  };
  const handleUnsupportedImageFileType = () => {
    setImageExtAlert(true);
  };
  const handleFaviconAlertClose = () => {
    setFaviconAlert(false);
  };
  const handleAlertClose1 = () => {
    setImageAlert1(false);
  };

  useEffect(() => {
    submitRef.current = () => {
      formik?.handleSubmit();
    };
  }, []);



  // const brandNameOptions = useMemo(() => {
  //   return mBrandData.map((item: any, index: any) => (
  //     <MenuItem key={index.mBrandID} value={item.mBrandName}>
  //       {item.mBrandName}
  //     </MenuItem>
  //   ));
  // }, [mBrandData]);

  const brandNameOptions = useMemo(() => {
    const sortedBrandData = [...mBrandData].sort((a, b) => {
      if (a.mBrandName < b.mBrandName) return -1;
      if (a.mBrandName > b.mBrandName) return 1;
      return 0;
    });
    return sortedBrandData.map((item, index) => (
      <MenuItem key={item.mBrandID} value={item.mBrandName}>
        {item.mBrandName}
      </MenuItem>
    ));
  }, [mBrandData]);



  return (<>

    <FormikProvider value={formik}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} md={6} lg={6}>
          <Typography fontWeight='bold' fontSize='14' color='#151D48' mb={2} ml={1}>Ad Preview</Typography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
            <FormGroup>
              <FormControlLabel
                sx={{ mr: 2, mb: 2 }}
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name='status'
                    checked={formik.values.status === 'Active'}
                    onChange={(event) => {
                      const newValue = event.target.checked ? 'Active' : 'Inactive';
                      formik.setFieldValue('status', newValue);
                    }}
                  />
                }
                label="Status"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
      <Card className="ad-preview" sx={{ p: "16px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={2}>
            {/* {!tcpaEditFlag ?
            (<Box className='info-1' height="100%" display="flex" justifyContent={{ xs: "flex-start", md: "center" }}>
              {tcpaLogo ? <CardMedia
                component="img"
                image={tcpaLogo}
              />
                : "No image"}
            </Box>):
            (<Box className='info-1' height="100%" display="flex" justifyContent={{ xs: "flex-start", md: "center" }}>
            {tcpaEditFlag ? <CardMedia
                component="img"
                image={formik.values.logo}
              />
                : "No image"}
            </Box>)
            } */}
            <Box className='info-1' height="100%" display="flex" justifyContent={{ xs: "flex-start", md: "center" }}>
              {(tcpaLogo || formik.values.logo) ? <CardMedia
                component="img"
                image={tcpaEditFlag ? formik.values.logo : tcpaLogo}
              />
                : "No image"}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={8}>
            <Box className='info-2'>
              <Typography component="div" variant="h5" fontWeight="600" mb={1}>
                {tcpaBrand ? tcpaBrand : "Brand Name"}
              </Typography>
              {/* <Typography
                variant="subtitle1"
                color="text.secondary"
                component="p"
                lineHeight={2}
                fontWeight="600"
              > */}
              {/* {formik.values.headContent} */}

              {/* </Typography> */}
              {formik.values.tcpaText && <Box display="flex" alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.tcpaCheckbox}
                      onChange={formik.handleChange}
                      name="tcpaCheckbox"
                      sx={{ marginTop: '-8px' }}
                    />
                  }
                  label=""
                  sx={{ marginRight: 1 }}  // Adjust spacing if needed
                />
                {formik.values.tcpaText}
              </Box>}
              {/* <ul style={{ marginBottom: '0' }}>
                {previewList.map((list, index) => (
                  <li key={index}>{list}</li>
                ))}
              </ul> */}
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Paper elevation={3} className="ad-campaign-form">

        <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Advertiser*</InputLabel>
              <Select
                // labelId="demo-simple-select-helper-label"
                // id="demo-simple-select-helper"
                value={formik.values.advertiserId}
                onChange={(e) => {
                  formik.handleChange(e);
                  dispatch(storeSelectedTcpaAdvertiser(e.target.value));
                  // handleCustomChange('mBrand')
                }}
                label="Advertiser*"
                onBlur={formik.handleBlur}
                name="advertiserId"
                // disabled={campaignFormData.isEdit || campaignFormData.isClone}
                disabled
              >
                {advertiserList.map((item: any, index: number) => {
                  return (
                    <MenuItem value={item.advertiserID} key={item.advertiserID}>{item.advName}</MenuItem>
                  )
                })}
              </Select>
              {/* {advertiserNameError.length > 0 &&
              <FormHelperText>{advertiserNameError}</FormHelperText>} */}
            </FormControl>
            {formik.errors.advertiserName && formik.touched.advertiserName ? (<Box color="#db2424">{formik.errors.advertiserName}</Box>) : null}
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label="Brand Name"
              name="mBrand"
              variant="outlined"
              value={tcpaEditFlag ? formik.values.mBrand : tcpaBrand}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Select mBrand</InputLabel>
            <Select
              value={formik.values.mBrand}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="mBrand"
              label="Select mBrand"
            >
              <MenuItem value="" selected>Select</MenuItem>
              {brandNameOptions}
            </Select>
          </FormControl>
        </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              label="URL Path"
              name="urlPath"
              variant="outlined"
              value={formik.values.urlPath}
              onChange={e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Grid> */}

          <Grid item xs={12}>
            <TextField
              label="Posting URL*"
              minRows={2}
              multiline
              name="postUrl"
              variant="outlined"
              maxRows={6}
              value={formik.values.postUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
            {formik.errors.postUrl && formik.touched.postUrl ? (<Box color="#db2424">{formik.errors.postUrl}</Box>) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              minRows={2}
              label="TCPA Text*"
              name="tcpaText"
              value={formik.values.tcpaText}
              multiline
              maxRows={4}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.tcpaText && formik.touched.tcpaText ? (<Box color="#db2424">{formik.errors.tcpaText}</Box>) : null}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Head Content*"
              name="headContent"
              variant="outlined"
              value={formik.values.headContent}
              onChange={e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              fullWidth
            />
            {formik.errors.headContent && formik.touched.headContent ? (<Box color="#db2424">{formik.errors.headContent}</Box>) : null}
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              label="Bid Price*"
              name='bid'
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              // variant="outlined"
              fullWidth
              // sx={{ mb: 2, ml: 1 }}
              value={formik.values.bid}
              onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            />
            {formik.errors.bid &&  formik.touched.bid ? (<Box color="#db2424">{formik.errors.bid}</Box>) : null} 
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              label="Bid Price*"
              name="bid"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: { min: 0 },  // This ensures only non-negative values are allowed
              }}
              fullWidth
              value={formik.values.bid}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (value >= 0 || e.target.value === "") { // Allow only positive or empty values
                  formik.setFieldValue('bid', e.target.value);
                }
              }}
            />
            {formik.errors.bid && formik.touched.bid ? (
              <Box color="#db2424">{formik.errors.bid}</Box>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              // minRows={2}
              label="Short Description*"
              name="shortDescription"
              value={formik.values.shortDescription}
              // multiline
              // maxRows={4}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.shortDescription && formik.touched.shortDescription ? (<Box color="#db2424">{formik.errors.shortDescription}</Box>) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              minRows={2}
              label="Description*"
              name="description"
              value={formik.values.description}
              multiline
              maxRows={4}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.description && formik.touched.description ? (<Box color="#db2424">{formik.errors.description}</Box>) : null}
          </Grid>
          <Grid item xs={6}>
            <TextField
              // minRows={2}
              label="Default Program*"
              name="defaultProgram"
              value={formik.values.defaultProgram}
              // multiline
              // maxRows={4}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.defaultProgram && formik.touched.defaultProgram ? (<Box color="#db2424">{formik.errors.defaultProgram}</Box>) : null}
          </Grid>
          <Grid item xs={6}>
            <TextField
              // minRows={2}
              label="Default Program Value*"
              name="defaultProgramValue"
              value={formik.values.defaultProgramValue}
              // multiline
              // maxRows={4}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.defaultProgramValue && formik.touched.defaultProgramValue ? (<Box color="#db2424">{formik.errors.defaultProgramValue}</Box>) : null}
          </Grid>
        </Grid>
        <Box>
          <FieldArray
            name="hiddenField"
            render={arrayHelpers => (
              <>
                {formik.values.hiddenField.map((item: any, index: number) => (
                  <div key={index}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        position: 'relative',
                        // marginBottom: '10px',
                        // display: 'flex',
                        // alignItems: 'center',
                        marginTop: '5px',
                        // border: '2px solid #ccc',
                        // borderRadius: '5px',
                        // padding:'16px'
                      }}
                    >
                      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
                        <TextField
                          label="Hidden Field Key"
                          variant="outlined"
                          name={`hiddenField.${index}.key`}
                          value={formik.values.hiddenField[index].key}
                          onChange={formik.handleChange}
                          fullWidth
                          style={{ marginTop: '3%' }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
                        <TextField
                          label="Hidden Field Value"
                          variant="outlined"
                          name={`hiddenField.${index}.value`}
                          value={formik.values.hiddenField[index].value}
                          onChange={formik.handleChange}
                          fullWidth
                          style={{ marginTop: '3%' }}
                        />
                      </Grid>
                      {index > 0 && (
                        <IconButton
                          aria-label="remove"
                          onClick={() => arrayHelpers.remove(index)}
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                          }}
                        >
                          <CancelIcon sx={{ color: '#f45d5d', fontSize: 40 }} />
                        </IconButton>
                      )}
                    </Grid>
                  </div>
                ))}
                <IconButton
                  aria-label="add"
                  onClick={() => arrayHelpers.push({ value: '', key: '' })}
                  sx={{
                    textDecoration: 'none',
                    color: '#18c0e2',
                    fontSize: '15px',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                >
                  + Add More
                </IconButton>
              </>
            )}
          />
        </Box>
      </Paper>
    </FormikProvider>

  </>);
};
