// Assume selectedEvents is an array containing the selected events from FullCalendar
import moment from 'moment';
import { JsonData, ProgramData } from '../Mock/ProgramTargetingData';
import { Key } from '@mui/icons-material';
import { createEventId } from '../Mock/Poc/FullCalender';
import 'moment-timezone';




interface ConvertedEvents {
  [dayOfWeek: string]: {
    [time: string]: {
      include: boolean;
    };
  };
}


export const formatEvents = (selectedEvents: any[]) => {

  const convertedEvents: ConvertedEvents = {};

  selectedEvents.forEach((event: any) => {
    const start = moment(event.start);
    const end = moment(event.end);

    const dayOfWeek = start.format('dddd');
    const startTime = start.format('hA');
    const endTime = end.format('hA');

    if (!convertedEvents[dayOfWeek]) {
      convertedEvents[dayOfWeek] = {};
    }

    const hours = end.diff(start, 'hours');
    let currentTime = start.clone();

    for (let i = 0; i <= hours; i++) {
      const currentTimeStr = currentTime.format('hA');
      convertedEvents[dayOfWeek][currentTimeStr] = { include: true };
      currentTime.add(1, 'hour');
    }

    // Set include value to false for the end time slot
    if (convertedEvents[dayOfWeek][endTime]) {
      convertedEvents[dayOfWeek][endTime].include = false;
    }


  });
  return convertedEvents
}


export const createEvents = (jsonData: ConvertedEvents) => {
  // Assume the provided JSON data is stored in a variable called `jsonData`

  const events = [];

  for (const dayOfWeek in jsonData) {
    const timeSlots = jsonData[dayOfWeek];

    for (const time in timeSlots) {
      const include = timeSlots[time].include;

      if (include) {
        const startTime = moment(`${dayOfWeek} ${time}`, 'dddd hA');
        const endTime = startTime.clone().add(1, 'hour');
        // const eventStart = new Date(`${todayStr} ${time}`);
        // const eventEnd = new Date(eventStart.getTime() + 3600000); // Event duration: 1 hour
        events.push({
          id: createEventId(),
          title: 'Campaign On', // Set your own event title
          start: startTime.toISOString(),
          end: endTime.toISOString(),
          allDay:false,
          overlap:false
        });
      }
    }
  }
  return events
  // console.log(events);

}

export const createEvents1 = (jsonData: ConvertedEvents) => {
  // Assume the provided JSON data is stored in a variable called `jsonData`

  const events = [];

  for (const dayOfWeek in jsonData) {
    const timeSlots = jsonData[dayOfWeek];
    let startTime;
      let endTime;
    for (const time in timeSlots) {
      const include = timeSlots[time].include;
     
      if (include) {
        if(!startTime){
          startTime = moment(`${dayOfWeek} ${time}`, 'dddd hA');
        }
        
         endTime = startTime.clone().add(1, 'hour');
        // const eventStart = new Date(`${todayStr} ${time}`);
        // const eventEnd = new Date(eventStart.getTime() + 3600000); // Event duration: 1 hour
        // events.push({
        //   id: createEventId(),
        //   title: 'Campaign On', // Set your own event title
        //   start: startTime.toISOString(),
        //   end: endTime.toISOString(),
        //   allDay:false,
        //   overlap:false
        // });
       
      }else{
        startTime = moment(`${dayOfWeek} ${time}`, 'dddd hA');
        endTime = startTime.clone().add(1, 'hour');

        // console.log("start time , if not included  ", startTime);
        // console.log("endTime if not included", endTime)
      }
    }
    //  events.push({
    //       id: createEventId(),
    //       title: 'Campaign On', // Set your own event title
    //       start: startTime?.toISOString(),
    //       end: endTime?.toISOString()
    //       // allDay:false,
    //       // overlap:false
    //     });

    // console.log("start time , ", startTime?.format('YYYY-MM-DD h:mm:ss a'))
    // console.log("endTime", endTime?.format('YYYY-MM-DD h:mm:ss a'))
  }
  //  return events
  // console.log(events);

}

export function generateEvents(data: ConvertedEvents) {
  const events:any[] = [];
  let currentEvent:any = null;

  for (const day in data) {
    for (const time in data[day]) {
      const include = data[day][time].include;

      if (include) {
        if (!currentEvent) {
          currentEvent = {
            id: generateThreeDigitUniqueId(),
            title: "Campaigns On time",
            start: moment(`${day} ${time}`, "dddd hA").toISOString(),
          };
        }
      } else {
        if (currentEvent) {
          currentEvent.end = moment(`${day} ${time}`, "dddd hA").toISOString();
          events.push(currentEvent);
          currentEvent = null;
        }
      }
    }

    if (currentEvent) {
      currentEvent["end"] = moment(`${day} 11:59PM`, "dddd hA").toISOString();
      events.push(currentEvent);
      currentEvent = null;
    }
  }

  return events;
}



export function generateThreeDigitUniqueId() {
  const min = 100;
  const max = 999;
  const uniqueId = Math.floor(Math.random() * (max - min + 1)) + min;

  return uniqueId.toString();
}


export const convertMprogramValueToPayload = (mPrograms: JsonData) => {
  const mProgramKey = Object.keys(mPrograms)

  // console.log("mProgram Keys",)
  const mProrgramsPayload: any = {}
  mProgramKey.forEach((item, index) => {
    const programByAreaStudy = mPrograms[item];
    programByAreaStudy.forEach((item: any, index: number) => {
      mProrgramsPayload[item.value] = item
    })
  })


  return mProrgramsPayload
}
interface program {
  text?: string;
  value?: string;
  id?: string;
  status?: boolean;
  trackingUrl?: string;
  areaStudy: string;
  bidPrice?: string;
}

type GroupedData = Record<string, program[]>;



export const mProgramResponseToComponentProps = (data: program[]) => {
  const grouped: GroupedData = {};

  for (const course of data) {
    if (!grouped[course.areaStudy]) {
      grouped[course.areaStudy] = [];
    }
    grouped[course.areaStudy].push(course);
  }

  return grouped;
}


export const mProgramResponseToComponentProps1 = (data: any) => {
  const grouped: any = {};
  // console.log("data ", data)
  for (const course of data) {
    if (!grouped[course.areaStudy]) {
      grouped[course.areaStudy] = [];
    }
    // console.log(`Index: ${index}, Value: ${course}`);
    // const updatedCourse={...course, "value":"100"}
    // console.log("updated course", updatedCourse)
    grouped[course.areaStudy].push(course);
  }

  return grouped;
};

export const addProgramValue = (mProgram: any) => {
  const output: any = {};
  for (const key in mProgram) {
    if (mProgram.hasOwnProperty(key)) {
      const originalItem = mProgram[key];
      const newItem = { ...originalItem, value: key, id:key };
      output[key] = newItem;
    }
  }
  return output
}

export function generateRequiredObjectForAudienceTargeting(inputData: any): any {
  const outputData: any = {};

  for (const categoryId in inputData) {
    const categoryItems = inputData[categoryId];
    const categoryOutput: { [value: string]: { bid: number; enable: boolean } } = {};

    for (const [index, item] of categoryItems.entries()) {
      categoryOutput[item.value] = {
        bid: item.bidPrice,
        enable: item.status,
      };
    }
    const newObj: any = {

    }
    newObj[categoryItems[0].categories] = {
      ...categoryOutput,
      title: categoryItems[0].categories,
    };
    //  console.log("new obj", newObj)
    //  console.log("op", outputData)
    Object.assign(outputData, { ...newObj })
  }
  // console.log("outPutData", inputData)

  // const formatViceVersa=mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps(outputData,inputData)
  // console.log("Mapped the payload datat for audince targeting to component props simmilar to input data for this function::::::::::::", formatViceVersa)
  return outputData;
}


export const mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps = (
  apiResponse: any,
  inputDataRef: any
) => {
  let outputDataRef = inputDataRef;
  const demo = { ...inputDataRef }
  const adjustmentKeys = ['campusType', 'highestEducation', 'degreeInterest', 'startTimeframe', 'militaryAffiliation', 'mediaType', 'inventoryType',]
  // console.log("OutputData ref :::::::::::::", outputDataRef);
  adjustmentKeys.forEach((item, index) => {
    const inputDataEachKeyItem = apiResponse[item];
    const keysOfChildObject = Object.keys(inputDataEachKeyItem).filter(key => key !== "title");;
    // console.log("child object keys", keysOfChildObject);
    let valueOfEachAdjustmentKey = demo[index + 1];
    const updatedObjects: any = [];
    keysOfChildObject.forEach((key, iv) => {
      const valueOfKey = inputDataEachKeyItem[key];
      // console.log("EXCLUDE ADJUSTMENT;;;;;;:::", valueOfEachAdjustmentKey[iv])
      if(valueOfEachAdjustmentKey[iv]){
        const updatedObject = {
          ...valueOfEachAdjustmentKey[iv],
          bidPrice: valueOfKey.bid,
          status: valueOfKey.enable
        }
      
        updatedObjects.push(updatedObject);
      }
      
    });

    demo[index + 1] = updatedObjects
    // console.log("UPdated demo objects which is output ", demo)
  });
  // console.log("new input ref update", demo);
  return demo;
};



export function getCampaignStatus(schedule:any) {
  // Object.keys(schedule).length>1
  
  if(schedule){
    const currentTime = moment().tz('America/Los_Angeles');; // Current time in local time zone
    const currentDay = currentTime.format('dddd'); // Get the current day of the week
    const currentHour = currentTime.format('hA'); // Get the current hour in AM/PM format
   
    // console.log("Current time ", currentTime)
    // console.log("Current Day", currentDay)
    // console.log("Currnet hour", currentHour)
    if (schedule[currentDay] && schedule[currentDay][currentHour]) {
        return schedule[currentDay][currentHour].include ? 'Active' : 'Paused';
    } else {
        return 'Paused';
    }
  }else{
    return "Active"
  }
  

}




export function generateRandomId() {
  // Generate a random UUID (v4)
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0; // Random number between 0 and 15
    const v = c === 'x' ? r : (r & 0x3 | 0x8); // Modify bits for version 4
    return v.toString(16); // Convert to hex and return as a string
  });

  // Prepend 's-' to the UUID
  return 's-' + uuid;
}


export const timezoneOptions={
  time1 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/New_York',
    timeStyle: 'medium',
  }),
  time2 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/Chicago',
    timeStyle: 'medium',
  }),
  time3 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/Los_Angeles',
    timeStyle: 'short',
  }),
  time4 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/Anchorage',
    timeStyle: 'medium',
  }),
  time5 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/Denver',
    timeStyle: 'medium',
  }),
  time6 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/Phoenix',
    timeStyle: 'medium',
  }),
  time7 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/Halifax',
    timeStyle: 'medium',
  }),
  time8 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'Pacific/Honolulu',
    timeStyle: 'medium',
  }),
  time9 : new Date().toLocaleTimeString('en-GB', {
    timeZone: 'America/New_York',
    timeStyle: 'long',
  })
  
}