import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { RootState } from '../../Store/index'; // Update the path to your Redux store
import { Box, Button, Checkbox, Chip, CircularProgress, DialogContentText, FormControl, FormGroup, FormHelperText, FormLabel, Input, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Snackbar, Tooltip, Typography, } from '@mui/material'
import MMTable, { IOSSwitch } from '../../Component/Table/MMTable'
import AddIcon from '@mui/icons-material/Add';
import { publiCampaignHeadCells } from '../../Component/Table/tableInterface';
import './PublisherList.css'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import Search from '../../Component/Search/Search';
import IconButton from '@mui/material/IconButton';
import { fetchCompApiData, filterPublisherSearch, searchPublisherCampaigns } from '../../Services/publisherTabApis';
import { setFields, setDayCapField, resetForm, statusHandler, specificDayToggle, setSelectedRadioValue, setEditFlag, setExcludeMbrandId, setCapSettingsField, setVerticalFields, setVerticalOption } from '../../Store/Slices/PublisherCampaignSlice';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { createPublisherCampAsync, setCampData, setCampFlag, setFrontEndFilSearch, setfilterSearchFlag, updatePublisherCampAsync } from '../../Store/Slices/PublisherTabApisData';
import { fetchPublisherCampApiData } from '../../Services/publisherTabApis';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
// import { DeleteIconPop } from '../Advertiser/Action';
import { resetFilters, setSearchFilterData } from '../../Store/Slices/PublisherCampaignSlice'
import { setBlockScroll, setIsoLoading, setNoDataFlag, setScrollNext, setSearchInput } from '../../Store/Slices/advertiserTabApisData';
import MbrandExclusion from '../Tools/MbrandExclusion';
import { getBrands, getBrandsByVertical, getVerticalByPublisher } from '../../service/Publisher'
import { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { fetchVerticals } from '../../Services/verticalList';
import { useSelector } from 'react-redux';
import { CreatePubliCampaigns } from './CreatePublisherCampaign';
export const CAMPAIGN_TYPE_OPTIONS = [
  {
    label: 'Clicks',
    value: 'Clicks'
  },
  {
    label: 'TCPA',
    value: 'TCPA'
  },
  {
    label: 'Leads',
    value: 'Leads'
  },
  {
    label: 'Email',
    value: 'Email'
  },

]

const PublisherCampaigns = () => {

  const dispatch = useAppDispatch();
  const publiCampaignTableData = useAppSelector((state) => state.publisherCampApi.datacamp);
  const isLoading = useAppSelector((state) => state.publisherCampApi.campLoading);
  // const compApiData = useAppSelector((state) => state.publisherCampApi.compApiDataCamp);
  const currentCursor = useAppSelector((state) => state.publisherCampApi.currentCampCursor);
  const hasMoreData = useAppSelector((state) => state.publisherCampApi.hasCampMoreData);
  const [openPopup, setOpenPopup] = React.useState(false);
  const fullDataPubli = useAppSelector((state) => state.publisherAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  // const [selectedOptions, setSelectedOptions] = useState<number[]>([]); // Specify the type as number[]
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const filterSearchFlag = useAppSelector((state) => state.publisherCampApi.filterSearchFlag);
  const filterSearchPubData = useAppSelector((state) => state.publisherCampApi.filterSearchPubData);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const campaignState = useAppSelector((state: RootState) => state.campaign);
  const { verticalFilter, searchFilterData } = campaignState
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  let updatedFilterData: any


  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(setSearchInput(''));
    };
  }, [dispatch]);
  useEffect(() => {
    // console.log('triggred1')
    dispatch(setfilterSearchFlag(false));
    setIsMouseOverDelete(false);
    dispatch(setCampFlag(false));
    setVerticalMouseOverDelete(false);
    if (!searchInput && searchFilterData.length === 0 && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setFrontEndFilSearch(''));
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      fetchInitialCampData();
      dispatch(setNoDataFlag(false));
      // fetchComCampApiData(dispatch);
    } else {
      if ((searchFilterData.length !== 0 && searchInput === '') || (verticalFilter.length !== 0 && searchInput === '')) {
        // console.log('triggred3')
        dispatch(setSearchInput(''));
        const debounceTimer = setTimeout(async () => {
          const payload = {
            "vertical": verticalFilter,
            "mPubIds": searchFilterData
          }
          { (verticalFilter.length > 0 || searchFilterData.length > 0) && filterPublisherSearch(payload, dispatch) }

        }, 1000);

        // Clear the timer on every key press to restart the countdown
        return () => clearTimeout(debounceTimer);
      }
      else {
        // console.log('triggred4')
        const debounceTimer = setTimeout(() => {
          setDebouncedInputValue(searchInput);
        }, 600);

        // Clear the timer on every key press to restart the countdown
        return () => clearTimeout(debounceTimer);
      }
    }
    // console.log('triggred5')
  }, [dispatch, searchInput, searchFilterData, verticalFilter]);

  useEffect(() => {
    // console.log('triggred6')

    dispatch(fetchVerticals())
    dispatch(setSearchFilterData([]));
    // dispatch(setSearchInput(''));
    fetchPublisherCampApiData(dispatch, null)
    // fetchComCampApiData(dispatch);
    fetchCompApiData(dispatch);
  }, [])
  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      dispatch(setSearchFilterData([]));
      searchPublisherCampaigns(debouncedInputValue, dispatch, 'campaign')
    }
  }, [debouncedInputValue])
  const fetchInitialCampData = () => {
    fetchPublisherCampApiData(dispatch, null).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (verticalFilter.length > 0 && !isLoading && hasMoreData) {
      dispatch(setCampFlag(true));
      const payload = {
        vertical: verticalFilter, // Already contains IDs
        mPubIds: searchFilterData,
      };
      if (verticalFilter.length > 0 || searchFilterData.length > 0) {
        filterPublisherSearch(payload, dispatch, currentCursor);
      }
    } else if (!isLoading && hasMoreData && verticalFilter.length === 0) {
      dispatch(setCampFlag(true));
      fetchPublisherCampApiData(dispatch, currentCursor);
    }
  };


  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchPubData.filter(function (item: any) {
        return (
          item.campaign_id?.toString().toLowerCase().includes(userInput) ||
          item.campaign_name?.toLowerCase().includes(userInput) ||
          item.Publisher_ID?.toString().toLowerCase().includes(userInput) ||
          item.Publisher_Name?.toLowerCase().includes(userInput) ||
          item.Status?.toString().toLocaleLowerCase().includes(userInput)
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setCampData(filteredDataa));
    } else {
      dispatch(setCampData(filterSearchPubData));
      // dispatch(appendCampData(filteredData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterSearchInput(value);
    //dispatch(setFrontEndFilSearch(value));
    searchTable(value)
  }

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchInput(event.target.value));
  }



  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }

  const handleChipRemove = (mPubID: number) => {
    updatedFilterData = searchFilterData.filter((id: number) => id !== mPubID);
    dispatch(setSearchFilterData(updatedFilterData));
  };

  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };
  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);

    setVerticalMouseOverDelete(false);
  };


  return (
    <>
      <Paper elevation={3} className="card" >
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
          <Box >
            <Typography className="card-heading" sx={{ border: "10px" }}>Publisher Campaign</Typography>
            <Typography className="card-sub-heading">
              Publisher Campaigns Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={filterSearchFlag ? filterSearchInput : searchInput} searchHandler={filterSearchFlag ? handleFilter : handelSearch} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenPopup(true);
                dispatch(setEditFlag(false));
              }}
              sx={{ backgroundColor: "#0ae" }}
            >
              Create Campaign
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl className='campaign-vertical-select'>
            <InputLabel style={{ paddingLeft: "19px" }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch();
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 1;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;
                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                )

              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>

          </FormControl>


          {/* Existing Publisher Filter Dropdown */}
          <FormControl className='campaign-pub-select'>
            <InputLabel style={{ paddingLeft: "15px" }}>Filter by Publisher</InputLabel>
            <Select
              label="Filter by Publisher"
              multiple
              value={searchFilterData}
              onChange={(e) => {
                const selectedIDs: any = e.target.value as string[];
                dispatch(setSearchFilterData(selectedIDs));
                handleFilterSearch();
              }}
              renderValue={(selected: string[]) => {
                const maxVisibleChips = 2;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {displayed.map((id: any) => {
                      const publisher = fullDataPubli.find((pub: any) => pub.mPubID === id);
                      return (
                        <Chip
                          key={id}
                          label={publisher?.publisherName}
                          onDelete={() => handleChipRemove(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setIsMouseOverDelete(true)}
                              onMouseLeave={() => setIsMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={isMouseOverDelete}
            >
              {fullDataPubli
                .filter((pub: any) => pub.publisherName)
                .sort((a: any, b: any) => a.publisherName.localeCompare(b.publisherName))
                .map((pub: any) => (
                  <MenuItem key={pub.mPubID} value={pub.mPubID}>
                    <Checkbox checked={searchFilterData.includes(pub.mPubID)} />
                    <ListItemText primary={`${pub.publisherName} - ${pub.mPubID}`} />
                  </MenuItem>
                ))}
            </Select>

          </FormControl>
        </Box>


        <MMTable
          tableData={publiCampaignTableData}
          tableHeadCell={publiCampaignHeadCells}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          isLoadingMore={isLoading}
          fetchInitialData={fetchInitialCampData}
          searchInput={debouncedInputValue}
        />
      </Paper>
      <CreatePubliCampaigns
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialCampData}
      />
    </>
  );

}

export default PublisherCampaigns

