import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, CardMedia, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Rating, Select, Snackbar, Switch, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import { brandNameDummy, mBrandsHeadCell } from '../../Component/Table/tableInterface'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { setData, setfilterSearchFlag, setFlag } from '../../Store/Slices/PublisherTabApisData'
import { setIsoLoading, setNoDataFlag, setScrollNext, setSearchFilterData } from '../../Store/Slices/advertiserTabApisData'
import Search from '../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import { fetchMpartnerApiData, fetchMpartnerCompApiData } from '../../Services/mPartnerActivation'
import MuiAlert from '@mui/material/Alert';
import { InputField } from '../User Accounts/CreateUser'
import CancelIcon from '@mui/icons-material/Cancel';
import { createMbrandAsync, resetFilters, resetMBrandForm, setMBrandData, setMBrandEditFlag, setMBrandField, setMBrandTableData, setOnlineOption, setRating, setSearchMbrandInput, storeBrandLogo1ImageURL, storeBrandLogo2ImageURL, updateMbrandAsync } from '../../Store/Slices/ExternalApiSlice'
import { RootState } from '../../Store'
import { DeleteIconPop } from '../Advertiser/Action'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { createMbrand, fetchMBrandApiData, filterBrandByVerticalSearch, updateMbrand } from '../../Services/externalApis'
import CloseIcon from '@mui/icons-material/Close';
import { uploadPrimaryLogoToS3, uploadSecondaryLogoToS3 } from '../../service/S3 Upload'
import { Link } from 'react-router-dom';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { Description } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { fetchVerticals } from '../../Services/verticalList'
import SnackbarMessage from '../../Component/Snackbar'

const BrandMapping = () => {

  const dispatch = useAppDispatch();
  // const publisherTableData = useAppSelector((state) => state.publisherAccApi.data);
  const mBrandState = useAppSelector((state) => state.mBrandHandlerApi);
  const mBrandTableData = useAppSelector((state) => state.mBrandHandlerApi.mBrandTableData);
  const mBrandData = useAppSelector((state) => state.mBrandHandlerApi.data);
  const verticalFilter = useAppSelector((state) => state.mBrandHandlerApi.verticalFilter);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');

  // const isLoading = useAppSelector((state) => state.publisherAccApi.loading);
  // const currentCursor = useAppSelector((state) => state.publisherAccApi.currentCursor);
  // const hasMoreData = useAppSelector((state) => state.publisherAccApi.hasMoreData);
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const searchMbrandInput = useAppSelector((state) => state.mBrandHandlerApi.searchMbrandInput);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setSearchFilterData([]));
    if (!searchMbrandInput && verticalFilter.length === 0) {
      // If searchInput is empty, fetch the initial data
      fetchMBrandApiData(dispatch);
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
    } else if (verticalFilter.length !== 0 && searchMbrandInput === '') {
      dispatch(setSearchMbrandInput(''));
      const debounceTimer = setTimeout(async () => {
        const payload = {
          "vertical": verticalFilter
        }
        verticalFilter.length > 0 && filterBrandByVerticalSearch(payload, dispatch);
      }, 1000);
      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    } else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchMbrandInput);
      }, 600);
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchMbrandInput, verticalFilter]);

  // useEffect(() => {
  //   dispatch(setFlag(false));
  //   dispatch(setSearchFilterData([]));
  //   if (!searchMbrandInput) {
  //     // If searchInput is empty, fetch the initial data
  //     fetchMBrandApiData(dispatch);
  //     dispatch(setScrollNext(false));
  //     dispatch(setNoDataFlag(false));
  //     // fetchInitialData();
  //     // fetchCompApiData(dispatch);
  //   }
  // }, [dispatch, searchMbrandInput]);

  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(setSearchMbrandInput(''));
    // fetchMBrandApiData(dispatch);
  }, [])


  const fetchInitialData = () => {
    fetchMBrandApiData(dispatch);
  };

  // const fetchMoreData = () => {
  //   if (!isLoading && hasMoreData) {
  //     dispatch(setFlag(true));
  //     fetchMpartnerApiData(dispatch, currentCursor);
  //   }
  // };

  //  //console.log("apiiieeiiiiiinow",compApiData)
  function searchTable(input: string) {
    // console.log('fire=====')
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = mBrandData.filter(function (item: any) {
        return (
          item.mBrandID?.toString().toLowerCase().includes(userInput) ||
          item.mBrandName?.toLowerCase().includes(userInput) ||
          item.vertical?.toLowerCase().includes(userInput)
          // item.website?.toString().toLocaleLowerCase().includes(userInput) ||
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setMBrandTableData(filteredDataa));
    } else {
      dispatch(setMBrandTableData(mBrandTableData));
      dispatch(setNoDataFlag(false));
    }
    // console.log('=====', mBrandData, mBrandTableData)
  }

  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch(setSearchMbrandInput(value));
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchMbrandInput, searchMbrandInput, searchTable]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchMbrandInput) {
        searchTable(searchMbrandInput);
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, [searchMbrandInput]);
  //--------------end search


  // const compApiData = mBrandTableData.map((item: any) => ({
  //   mBrandID: item.mBrandID,
  // }));
  // const maxBrandID = compApiData.reduce((max: number, obj: { mBrandID: number }) => Math.max(max, obj.mBrandID), 0);

  // const maxBrandID = mBrandTableData.reduce((max, item) => {
  //   if (item.mBrandID !== undefined) {
  //     return Math.max(max, item.mBrandID);
  //   }
  //   return max;
  // }, 0);

  //  console.log('maxBrandID',maxBrandID) 

  const handleFieldChange = (field: keyof typeof mBrandState, value: any) => {
    dispatch(setMBrandField({ field, value }));
  };
  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };
  const handleFilterSearch = () => {
    dispatch(setSearchMbrandInput(''));
  }
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">Brands</Typography>
            <Typography className="card-sub-heading">
              Brand Mapping
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={searchMbrandInput}
              searchHandler={handleSearchInput}
            />
            <Button variant="contained" startIcon={<AddIcon />}
              onClick={() => { setOpenPopup(true); dispatch(setMBrandEditFlag(false)) }}>
              Create Brand
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch()
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <MMTable tableData={mBrandTableData} tableHeadCell={mBrandsHeadCell}
          // isLoadingMore={isLoading}

          //      fetchMoreData={() => fetchMoreData()} hasMoreData={hasMoreData} 
          fetchInitialData={fetchInitialData}
          defaultSortOrder='mBrandName'
        />

      </Paper>
      <CreateMbrands
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialData}
      // maxBrandID={maxBrandID}
      />
    </React.Fragment>
  )
}

export default BrandMapping

export const CreateMbrands: React.FC<any> = (props) => {

  const { openPopup, setOpenPopup, fetchInitialData, rowData } = props;
  const [errorMessage, setErrorMessage] = useState('');
  // const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const mBrandState = useAppSelector((state) => state.mBrandHandlerApi);
  const [mBrandNameError, setmBrandNameError] = useState(false);
  const [primaryLogoError, setPrimaryLogoError] = useState(false);
  const [secondaryLogoError, setSecondaryLogoError] = useState(false);
  const mBrandTableData = useAppSelector((state) => state.mBrandHandlerApi.mBrandTableData);

  // const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);

  const [emailTouched, setEmailTouched] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRef2 = useRef<HTMLInputElement | null>(null);
  const [fileLogo1, setFileLogo1] = useState<File | null>(null);
  const [fileLogo2, setFileLogo2] = useState<File | null>(null);
  const [readResult1, setReaderResult1] = useState<string>('');
  const [readResult2, setReaderResult2] = useState<string>('');
  const [verticalNameError, setVerticalNameError] = useState(false);
  const [snackbarType, setSnackbarType] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    verticalID: item.verticalID,
    verticalName: item.verticalName,
  }));
  const dispatch = useAppDispatch();

  const maxBrandID = mBrandTableData.reduce((max, item) => {
    if (item.mBrandID !== undefined) {
      return Math.max(max, item.mBrandID);
    }
    return max;
  }, 0);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleFieldChange = (field: keyof typeof mBrandState, value: string) => {
    console.log('mbrand------', field, value)
    dispatch(setMBrandField({ field, value }));
  };
  const handleCancel = () => {
    dispatch(resetMBrandForm());
    setOpenPopup(false);
    setmBrandNameError(false);
    setPrimaryLogoError(false);
    setSecondaryLogoError(false);
    setVerticalNameError(false);
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleButtonClick2 = () => {
    fileInputRef2.current?.click();
  };


  const handelUploadLogo1 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    var img = new Image();
    img.src = event.target.value;

    img.onload = function () {
      var width = img.width;
      var height = img.height;
      // callback(width, height);

      // if (height <= 500 && width <= 250) {
      dispatch(storeBrandLogo1ImageURL(event.target.value))
      //   setSnackbarOpen(false);
      //   setSnackbarType('error')
      //   setImageValidationMessage("")
      // } else {
      //   // alert('Image dimensions must be 500x250 pixels.');
      //   setSnackbarOpen(true);
      //   setSnackbarType('error')
      //   setImageValidationMessage("Image dimensions must be 500x250 pixels.")
      // }

    }
  }

  const handelUploadLogo2 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    var img = new Image();
    img.src = event.target.value;

    img.onload = function () {
      var width = img.width;
      var height = img.height;

      dispatch(storeBrandLogo2ImageURL(event.target.value))


    }
  }


  const handleFileInputChangeLogo1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSuccessMessage('');
    setErrorMessage('');
    if (file) {
      // Check if the file type is image/jpeg or image/png
      if (file.type === 'image/webp') {
        const reader = new FileReader();

        reader.onloadend = async () => {
          const image = new Image();
          image.src = reader.result as string;
          setReaderResult1(image.src)
          image.onload = () => {
            //console.log("image.height")
            var width = image.width;
            var height = image.height;

            // console.log('Image width: ', image);
            //console.log('Image height: ' + height);
            if (image.height <= 250 && image.width <= 500) {
              // Image is the correct size
              // console.log('file',file)
              setFileLogo1(file);
              // const objectURL = uploadPrimaryLogoToS3(reader.result, `${new Date().getTime()}`, file);
              // //console.log("object URL", objectURL);

              // objectURL.then((res: any) => {
              //   // //console.log("res", res)
              dispatch(storeBrandLogo1ImageURL(URL.createObjectURL(file)));
              //   setSnackbarOpen(false);
              //   // setSnackbarType('error')
              //   // setImageValidationMessage("")

              // })
              // dispatch(storeCampaignAdImageFile(objectURL));
              // setSnackbarOpen(false);
              // setSnackbarType('error')
              // setImageValidationMessage("")
            } else {
              // Reset the file input and preview because it doesn't match the required dimensions
              event.target.value = '';

              mBrandState.logo1 ? dispatch(storeBrandLogo1ImageURL(mBrandState.logo1)) : dispatch(storeBrandLogo1ImageURL(""));
              // alert('Image dimensions must be 500x250 pixels.');
              setErrorMessage('Image dimensions must be 500x250 pixels.');
              setSnackbarOpen(true);
              // setSnackbarType('error')
              // setImageValidationMessage("Image dimensions must be 500x250 pixels.")
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // File type not supported, reset the file input and preview
        event.target.value = '';
        dispatch(storeBrandLogo1ImageURL(""));
        alert('Only Webp image files are accepted.');
      }
    }
  };

  const handleFileInputChangeLogo2 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Check if the file type is image/jpeg or image/png
      if (file.type === 'image/webp') {
        const reader = new FileReader();

        reader.onloadend = async () => {
          const image = new Image();
          image.src = reader.result as string;
          setReaderResult2(image.src)

          image.onload = () => {
            setSuccessMessage('');
            setErrorMessage('');
            var width = image.width;
            var height = image.height;
            if (image.height <= 40 && image.width <= 40) {
              // Image is the correct size
              // const objectURL = uploadSecondaryLogoToS3(reader.result, `${new Date().getTime()}`, file);
              setFileLogo2(file);
              // objectURL.then((res: any) => {
              dispatch(storeBrandLogo2ImageURL(URL.createObjectURL(file)));
              // setSnackbarOpen(false);


              // })

            } else {
              // Reset the file input and preview because it doesn't match the required dimensions
              event.target.value = '';
              setErrorMessage('Image dimensions must be 40x40 pixels.');
              mBrandState.logo2 ? dispatch(storeBrandLogo2ImageURL(mBrandState.logo2)) : dispatch(storeBrandLogo2ImageURL(""));
              setSnackbarOpen(true);

            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // File type not supported, reset the file input and preview
        event.target.value = '';
        dispatch(storeBrandLogo2ImageURL(""));
        alert('Only Webp image files are accepted.');

      }
    }
  };
  const showSnackbar = (message: string, type: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 3000); // 3 seconds timeout
  };
  const handleSubmit = async () => {
    setSuccessMessage('');
    setErrorMessage('');

    if (!mBrandState.mBrandName.trim() || !mBrandState.logo1.trim() || !mBrandState.vertical?.verticalID) {
      // Validation checks
      setmBrandNameError(!mBrandState.mBrandName.trim());
      setPrimaryLogoError(!mBrandState.logo1.trim());
      setVerticalNameError(!mBrandState.vertical?.verticalID);
    } else {
      let primaryLogoURL;
      let secondaryLogoURL;
      setOpenPopup(false);
      if (fileLogo1) {
        try {
          if (mBrandState.mBrandEditFlag) {
            primaryLogoURL = await uploadPrimaryLogoToS3(readResult1, rowData?.mBrandId, fileLogo1);
            dispatch(storeBrandLogo1ImageURL(primaryLogoURL));
          } else {
            primaryLogoURL = await uploadPrimaryLogoToS3(readResult1, maxBrandID + 1, fileLogo1);
            dispatch(storeBrandLogo1ImageURL(primaryLogoURL));
          }
        } catch (error) {
          showSnackbar('Failed to upload primary logo.', 'error');
          return;
        }
      }

      if (fileLogo2) {
        try {
          if (mBrandState.mBrandEditFlag) {
            secondaryLogoURL = await uploadSecondaryLogoToS3(readResult2, rowData?.mBrandId, fileLogo2);
            dispatch(storeBrandLogo2ImageURL(secondaryLogoURL));
          } else {
            secondaryLogoURL = await uploadSecondaryLogoToS3(readResult2, maxBrandID + 1, fileLogo2);
            dispatch(storeBrandLogo2ImageURL(secondaryLogoURL));
          }
        } catch (error) {
          showSnackbar('Failed to upload secondary logo.', 'error');
          return;
        }
      }
      dispatch(setIsoLoading(true));
      const payload = {
        mBrandId: maxBrandID + 1,
        mBrandName: mBrandState.mBrandName,
        vertical: mBrandState.vertical,
        description: mBrandState.description,
        rating: mBrandState.rating ?? 0,
        onlineOption: mBrandState.onlineOption,
        primaryLogo: primaryLogoURL,
        secondaryLogo: secondaryLogoURL,
      };

      const requestData = {
        mBrandName: mBrandState.mBrandName,
        mBrandId: rowData?.mBrandId,
        vertical: mBrandState.vertical,
        description: mBrandState.description,
        rating: mBrandState.rating ?? 0,
        onlineOption: mBrandState.onlineOption,
        primaryLogo: primaryLogoURL || rowData?.primaryLogo,
        secondaryLogo: secondaryLogoURL || rowData?.secondaryLogo,
      };

      try {
        if (mBrandState.mBrandEditFlag) {
          await updateMbrand(requestData);
          dispatch(setIsoLoading(false));

          showSnackbar('MBrand Updated Successfully', 'success');
          dispatch(resetMBrandForm());
        } else {
          await createMbrand(payload);
          dispatch(setIsoLoading(false));
          showSnackbar('MBrand Created Successfully', 'success');
          dispatch(resetMBrandForm());
        }
      } catch (error: any) {
        console.error('Error:', error);
        dispatch(setIsoLoading(false));
        showSnackbar('Failed to process MBrand', 'error');
      }
      fetchMBrandApiData(dispatch);
      setOpenPopup(false);

    }
  };

  const handleOnlineChange = (field: any) => (event: any) => {
    // Dispatch the action with the current checked value of the checkbox
    dispatch(setOnlineOption({ field, value: event.target.checked }));
  };

  const handleRatingChange = (event: any, newValue: any) => {
    dispatch(setRating(newValue));
  };

  const isSmallScreen = useMediaQuery('(max-width:900px)');

  return (
    <div>
      <Dialog open={openPopup} onClose={() => console.log('closeddd')} fullWidth maxWidth="md">
        {/* <DialogTitle id="alert-dialog-title">
            <Box className="row-0 card">
              <Typography className="card-heading">
                {mBrandState.mBrandEditFlag ? 'Update MBrand' : 'Create MBrand'}
              </Typography>
            </Box>
          </DialogTitle> */}
        <DialogTitle> {mBrandState.mBrandEditFlag ? 'Update Brand' : 'Create Brand'}</DialogTitle>
        {/* <div className="close-button" onClick={handleCancel}>
            <CancelIcon style={{ fontSize: '32px' }} />
          </div> */}
        <div className="close-button" onClick={handleCancel}>
          <CloseIcon style={{ fontSize: '32px' }} /></div>
        <DialogContent className='Update-user'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isSmallScreen ? 12 : 7}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} lg={!mBrandState.mBrandEditFlag? 12: 6} sx={{pb:'10px'}}> */}
                <Grid item xs={12} lg={!mBrandState.mBrandEditFlag ? 12 : 8} sx={{ pb: '10px' }}>
                  <TextField label="Brand Name" name='mBrandName' value={mBrandState.mBrandName} fullWidth
                    onChange={(e) => {
                      setmBrandNameError(false);
                      handleFieldChange('mBrandName', e.target.value)
                    }} hidden={false} required={true}
                    error={mBrandNameError}
                    disabled={mBrandState.mBrandEditFlag}
                    helperText={mBrandNameError ? 'Brand Name is mandatory' : ''} />

                </Grid>

                {mBrandState.mBrandEditFlag && <Grid item xs={4} sx={{ pb: '10px' }}>
                  <TextField label="Brand ID" name='mBrandID' value={mBrandState.mBrandID} fullWidth disabled
                    onChange={(e) => handleFieldChange('mBrandID', e.target.value)} hidden={false} required={true} />
                </Grid>}
                <Grid item xs={12} lg={12} sx={{ pb: '10px' }}>
                  <FormControl fullWidth required>
                    <InputLabel>Vertical</InputLabel>
                    <Select
                      disabled={mBrandState.mBrandEditFlag}
                      label="Vertical"
                      value={mBrandState.vertical?.verticalID || ''}
                      name="mBrandCategory"
                      onChange={(e) => {
                        const selectedVertical: any = verticalOptions.find(
                          (option) => option.verticalID === e.target.value
                        );
                        setVerticalNameError(false);
                        handleFieldChange('vertical', selectedVertical);
                      }}
                      error={verticalNameError}
                    >
                      {verticalOptions.map((option) => (
                        <MenuItem key={option.verticalID} value={option.verticalID}>
                          {option.verticalName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {verticalNameError && <div style={{ color: '#d32f2f', fontSize: '12px' }}>Please select Vertical</div>}

                </Grid>
                <Grid item xs={12}>
                  <TextField label="Description" fullWidth variant="outlined" rows={3} multiline
                    value={mBrandState.description}
                    onChange={(e) => handleFieldChange('description', e.target.value)} />
                </Grid>

                {/* <Grid item xs={12}>
                    <Box className="row-2">
                   <InputField label="Logo" name='logo1'
                  value={mBrandState.logo1}
                  changeHandler={(e)=>handleFieldChange('logo1', e.target.value)}

                  hidden={false} required={true} error={''} />
                    </Box>
                    </Grid> */}
                {/* <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <FormControl fullWidth>
                    <TextField
                      label='Upload Primary Logo (500x250)'
                      onChange={(e) => {
                        // setPrimaryLogoError(false);
                        handelUploadLogo1(e) }}
                      value={mBrandState.logo1}
                      error={primaryLogoError}
                      helperText={primaryLogoError ? 'Primary Logo is mandatory' : ''}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={(e) => {
                        setPrimaryLogoError(false);
                        handleFileInputChangeLogo1(e)
                      }}

                    />
                  </FormControl>
                  <Button onClick={handleButtonClick} variant='contained' sx={{ whiteSpace: "nowrap", height: "56px", boxShadow: "none", padding: '6px 21px' }}>Upload</Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                {mBrandState.logo1 && <CardMedia
                  component="img"
                  image={mBrandState.logo1}
                />}
              </Box></Grid> */}


              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <FormControl fullWidth>
                    <TextField
                      label='Upload Secoundary Logo (40x40)'
                      onChange={(e) => { 
                        // setSecondaryLogoError(false);
                        handelUploadLogo2(e) }}
                      value={mBrandState.logo2}
                      // error={secondaryLogoError}
                      // helperText={secondaryLogoError ? 'Secondary Logo is mandatory' : ''}
                    />
                    <input
                      type="file"
                      ref={fileInputRef2}
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={(e)=> {
                        setSecondaryLogoError(false);
                        handleFileInputChangeLogo2(e)
                      }}

                    />
                  </FormControl>
                  <Button onClick={handleButtonClick2} variant='contained' sx={{ whiteSpace: "nowrap", height: "56px", boxShadow: "none", padding: '6px 21px' }}>Upload </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                {mBrandState.logo2 && <CardMedia sx={{width:'auto'}}
                  component="img"
                  image={mBrandState.logo2}
                />}
              </Box></Grid> */}

            {/* <Grid item xs={12}>
                <Box className="row-3">
                  <InputField label="Logo" name='logo2'
                    value={mBrandState.logo2}
                    changeHandler={(e) => handleFieldChange('logo2', e.target.value)}
                    error={''} hidden={false} required={true} />
                </Box>
              </Grid> */}

            <Grid item xs={12} md={isSmallScreen ? 12 : 5}>

              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography sx={{ textAlign: 'center' }} variant="h6" gutterBottom>
                  Check and Rating
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      {/* <FormGroup row> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={mBrandState.onlineOption.online100}
                            onChange={handleOnlineChange('online100')}
                            name="online100"
                          />
                        }
                        label="100% Online"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={mBrandState.onlineOption.onlineCampus}
                            onChange={handleOnlineChange('onlineCampus')}
                            name="onlineCampus"
                          />
                        }
                        label="Online or Campus"
                      />
                      {/* </FormGroup> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="legend" sx={{ fontSize: '1.1rem', fontWeight: '400' }}>Rate the Brand</Typography>
                    <Rating
                      name="brand-rating"
                      value={mBrandState.rating || 0} // default value 0 if not rated yet
                      onChange={handleRatingChange}
                      precision={1} // Allows whole number steps in the rating
                      sx={{ fontSize: '2.5rem' }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', cursor: 'pointer', justifyContent: 'center', height: '100%' }} onClick={handleButtonClick}>
                    {mBrandState.logo1 ? (
                      <CardMedia
                        component="img"
                        image={mBrandState.logo1}
                        sx={{ width: '70%', height: '100%', border: '1px solid #ccc', padding: '2px' }} // Example size for primary logo
                      />
                    ) : (
                      <Box sx={{ width: '60%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px dashed #ccc', color: '#1565C0' }}>
                        Click to upload Primary Logo 500x250 px
                      </Box>
                    )}
                    {primaryLogoError && (
                      <div
                        style={{
                          color: "#d32f2f",
                          fontSize: "13px",
                          paddingLeft: "12px",
                        }}
                      > Primary Logo is mandatory</div>
                    )}
                  </Box>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    // onChange={handleFileInputChangeLogo1}
                    onChange={(e) => {
                      setPrimaryLogoError(false)
                      handleFileInputChangeLogo1(e)
                    }}

                  />
                </Grid>

                {/* Secondary Logo */}
                <Grid item xs={5}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', cursor: 'pointer', justifyContent: 'center', height: '100%' }} onClick={handleButtonClick2}>
                    {mBrandState.logo2 ? (
                      <CardMedia
                        component="img"
                        image={mBrandState.logo2}
                        sx={{ width: 70, height: 70, border: '1px solid #ccc' }} // Example size for secondary logo
                      />
                    ) : (
                      <Box sx={{ width: '85%', height: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px dashed #ccc', color: '#1565C0' }}>
                        Click to upload Secondary Logo 40x40 px
                      </Box>
                    )}
                  </Box>
                  <input
                    type="file"
                    ref={fileInputRef2}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleFileInputChangeLogo2}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} style={{ color: 'red' }} >CANCEL</Button>
          <Button variant="contained" onClick={handleSubmit} color='primary'>{mBrandState.mBrandEditFlag ? 'Update' : 'Create'}</Button>
        </DialogActions>

      </Dialog>
      <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false); }} type={snackbarType} message={snackbarMessage} />
    </div>
  )
}

export const MBrandActionIcons = (props: any) => {
  const { mBrandID, fetchInitialData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.mBrandHandlerApi.compData);
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const mBrandState = useAppSelector((state: RootState) => state.mBrandHandlerApi);
  setMBrandEditFlag(true);

  const getRowDataById = (ID: string) => {
    return fullData.find((row: any) => row.mBrandId === ID);
  };
  const rowData = getRowDataById(mBrandID);

  const editFetch = () => {
    if (mBrandState.mBrandEditFlag && rowData) {

      dispatch(setMBrandField({ field: 'mBrandName', value: rowData.mBrandName || '' }));
      dispatch(setMBrandField({ field: 'mBrandID', value: rowData.mBrandId || '' }));
      dispatch(setMBrandField({ field: 'description', value: rowData.description || '' }));
      dispatch(setMBrandField({ field: 'vertical', value: rowData.vertical || '' }));
      dispatch(setMBrandField({ field: 'rating', value: rowData.rating || 0 }));
      dispatch(setMBrandField({ field: 'onlineOption', value: rowData.onlineOption || { online100: false, onlineCampus: false } }));
      dispatch(storeBrandLogo1ImageURL(rowData?.primaryLogo || ''));
      dispatch(storeBrandLogo2ImageURL(rowData?.secondaryLogo || ''));

    }
  };

  const handleEditButtonClick = () => {
    editFetch();
    setOpenPopup(true);
  };

  const fetchData = () => {
    fetchMBrandApiData(dispatch);
  };

  // let delPayload: { ID: any; mPubID: any; }
  // delPayload = {
  //   ID: rowData?.ID,
  //   mPubID: rowData?.mPubID,
  // }

  const delPayload = rowData?.mBrandId;

  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
      {/* <DeleteIcon onClick={() => //console.log("delete ",rowData, fullData)} color="error" /> */}
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>

      {/* <Tooltip title="Exclude Publisher">
        <Link to='/mBrand-exclusion' state={{rowData:rowData}}>
        <AdUnitsIcon   color="primary"  />
        </Link>
        </Tooltip>  */}


      <CreateMbrands openPopup={openPopup} setOpenPopup={setOpenPopup}
        rowData={rowData}
        fetchInitialData={fetchInitialData}
      />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData}
      />
    </div>
  )
}

