import React, { useEffect, useMemo, useState } from 'react';
import PublisherStagingJSON from '../../Mock/PublishersStaging.json';
import { Box, Checkbox, Chip, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Typography } from '@mui/material';
import MMTable from '../../Component/Table/MMTable';
import { cpcThresholdHeadCell, mPubMarginHeadCell } from '../../Component/Table/tableInterface';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { setCpcData, setData, setFlag } from '../../Store/Slices/PublisherTabApisData';
import CloseIcon from '@mui/icons-material/Close';
import {
  setBlockScroll,
  setNoDataFlag,
  setScrollNext,
  setSearchFilterData,
  setSearchInput,
} from '../../Store/Slices/advertiserTabApisData';
import Search from '../../Component/Search/Search';

// import { mpartnerActivationHeadCells } from '../../Component/Table/tableInterface';
import {
  fetchCpcApiData,
  fetcCpcCompApiData,
  filterCpcCompApiData
} from '../../Services/mPartnerActivation';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../Store';
import { filterPublisherSearch } from '../../Services/publisherTabApis';
import { setFields, resetFilters } from '../../Store/Slices/PublisherCampaignSlice';
import { useSelector } from 'react-redux';
import { fetchVerticals } from '../../Services/verticalList';

const CpcThreshold = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const campaignState = useAppSelector((state: RootState) => state.campaign);
  const { verticalFilter, searchFilterData } = campaignState
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  const publisherTableData = useAppSelector(
    state => state.publisherAccApi.cpcData
  );
  const compApiData = useAppSelector(
    state => state.publisherAccApi.compApiCpcData
  );
  const isLoading = useAppSelector(state => state.publisherAccApi.loading);
  const currentCursor = useAppSelector(
    state => state.publisherAccApi.currentCursor
  );
  const hasMoreData = useAppSelector(
    state => state.publisherAccApi.hasMoreData
  );
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const searchInput = useAppSelector(
    state => state.advertiserAccApi.searchInput
  );
  useEffect(() => {
    return () => {
      dispatch(setSearchInput(''));
      dispatch(resetFilters());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setSearchFilterData([]));
    if (!searchInput && searchFilterData.length === 0 && verticalFilter.length === 0) {
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      fetchInitialData();
      // fetchCompApiData(dispatch);
    }
  }, [dispatch, searchInput, verticalFilter]);

  useEffect(() => {
    dispatch(setFlag(false));

    if (!searchInput && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      dispatch(setSearchInput(''));
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      setDebouncedInputValue('');
      fetchInitialData();
    } else if (verticalFilter.length !== 0 && searchInput === '') {
      const debounceTimer = setTimeout(() => {
        const payload = { vertical: verticalFilter };
        filterCpcCompApiData(payload, dispatch);
      }, 1000);
      return () => clearTimeout(debounceTimer);
    } else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput, verticalFilter,]);


  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(setSearchInput(''));
    fetcCpcCompApiData(dispatch, location);
  }, []);

  const fetchInitialData = () => {
    fetchCpcApiData(dispatch, null, location).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchCpcApiData(dispatch, currentCursor, location);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      let filteredDataa = compApiData.filter(function (item: any) {
        return (
          item.publisherID?.toString().toLowerCase().includes(userInput) ||
          item.publisherName?.toLowerCase().includes(userInput)
          // item.website?.toString().toLocaleLowerCase().includes(userInput) ||
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      console.log(filteredDataa)
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else {
        dispatch(setNoDataFlag(false));
      }
      dispatch(setCpcData(filteredDataa));
    } else {
      dispatch(setCpcData(publisherTableData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch(setSearchInput(value));
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchInput, searchTable]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchInput) {
        searchTable(searchInput);
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, [searchInput]);
  //--------------end search
  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };
  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }
  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className="card-heading">CPC Threshold</Typography>
            <Typography className="card-sub-heading">
              CPC Threshold Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Search value={searchInput} searchHandler={handleSearchInput} />
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch()
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable
          tableData={publisherTableData}
          tableHeadCell={cpcThresholdHeadCell}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
          //   hasEdit
          //   onEdit={(row:any) => {
          //     console.log(row);
          //   }}
          editComponentNavigation="/cpc-threshold-config"
        />
      </Paper>
    </React.Fragment>
  );
};

export default CpcThreshold;
