import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { getBrandsByVertical, getVerticalByPublisher } from '../../service/Publisher';
import { resetForm, setExcludeMbrandId, setFields, setVerticalFields, setVerticalOption, statusHandler, storeBrands } from '../../Store/Slices/PublisherCampaignSlice';
import { setIsoLoading } from '../../Store/Slices/advertiserTabApisData';
import { RootState } from '../../Store';
import { CAMPAIGN_TYPE_OPTIONS } from './Campaigns';
import { createPublisherCampAsync, setCampFlag, updatePublisherCampAsync } from '../../Store/Slices/PublisherTabApisData';
import { fetchCompApiData, fetchPublisherCampApiData, filterPublisherSearch, searchPublisherCampaigns } from '../../Services/publisherTabApis';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import { Box, Button, Checkbox, Chip, CircularProgress, DialogContentText, FormControl, FormGroup, FormHelperText, FormLabel, Input, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Snackbar, Tooltip, Typography, } from '@mui/material'
import Search from '../../Component/Search/Search';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import MbrandExclusion from '../Tools/MbrandExclusion';
export interface BrandData {
  mBrandName: string;
  mBrandId: number;
  primaryLogo: string;
  createdOn: string;
  secondaryLogo: string;
}

export const getBrandsData = async (value: any) => {
  const response = await getBrandsByVertical(value)
  return response
}
export const CreatePubliCampaigns: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const campaignState = useAppSelector((state: RootState) => state.campaign);

  const { openPopup, setOpenPopup, rowData, fetchInitialData, searchInput } = props;
  const fullDataPubli = useAppSelector((state) => state.publisherAccApi.fullData);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [publisherNameError, setPublisherNameError] = useState(false);
  const [campaignNameError, setCampaignNameError] = useState(false);
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);
  const [verticalNameError, setVerticalNameError] = useState(false);
  const [campaignType, setCampaignType] = React.useState(rowData?.campaignClass ? rowData?.campaignClass : CAMPAIGN_TYPE_OPTIONS[0].value);

  const getRowDataBycamId = (camId: string) => {
    // console.log('camId', fullDataPubli)
    return fullDataPubli.find((row: any) => row?.mPubID === camId);
  };
  const RowDataPay = getRowDataBycamId(campaignState.mPubID);

  const [brands, setBrands] = useState<BrandData[]>(campaignState.editFlag ? campaignState.brands : []);
  const [loading, setLoading] = useState(false)
  const [verticalLoading, setVerticalLoading] = useState(false)
  const fetchBrands = async (value: any) => {
    try {
      setLoading(true)
      const response = await getBrandsData(value);
      console.log("response", response.data)
      setBrands(response.data);
      setLoading(false)
    } catch (error) {
      setLoading(true)
      console.error('Error fetching brands:', error);
    }
  };
  const fetchVerticals = async (value: any) => {
    try {

      setVerticalLoading(true);
      const response = await getVerticalByPublisher(value);
      dispatch(setVerticalOption(response?.data[0].vertical || []))

      // setVerticalOptionsById(response?.data[0].vertical || []);
      setVerticalLoading(false);
    } catch (error) {
      console.error('Error fetching verticals:', error);
      setVerticalLoading(false);
    }
  };

  useEffect(() => {
    if (campaignState.editFlag) {
      setBrands(campaignState.brands); // Set the brands if editFlag is true
    } else {
      setBrands([]);
    }
  }, [campaignState.brands]);
  // console.log("count API call ", count)
  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };
  const handleVerticalChange = (value: string) => {
    const selectedVertical = campaignState.verticalOptions.find(
      (vertical: any) => vertical.verticalID === value
    );
    dispatch(setFields({ field: 'vertical', value: selectedVertical }));
    fetchBrands(value)
  }
  const [open, setOpen] = useState(false);
  const [matchingIds, setMatchingIds] = useState<string[]>([]);

  const handleConfirm = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    // clearSelectedFile();
    setOpen(false);
  };


  const [campaignTypeError, setCampaignTypeError] = useState(false)
  const handleCreateCampaign = async () => {



    setErrorMessage('');
    setSuccessMessage('');
    if (!campaignState.publisherName.trim() || !campaignState.campaignname.trim() || !campaignState.vertical) {
      setPublisherNameError(!campaignState.publisherName.trim());
      setCampaignNameError(!campaignState.campaignname.trim());
      setVerticalNameError(!campaignState.vertical)
    } else if (campaignState.campaignname.includes(',')) {
      setErrorMessage('Comma is not allowed in Publisher Camp Name');
      setSnackbarOpen(true);
      return;
    } else {
      setSuccessMessage('');
      setErrorMessage('');
      setOpenPopup(false);
      // dispatch(storeBrands([]))
      dispatch(setIsoLoading(true));
      setPublisherNameError(false);
      setCampaignNameError(false);
      setVerticalNameError(false);
      // delete RowDataPay?.campaignCount;

      const payload: any = {
        campaignClass: campaignType,
        advertiserStatus: RowDataPay?.status,
        campaignname: campaignState.campaignname,
        campaignType: 'publisherCampaign',
        publisher: RowDataPay,
        vertical: campaignState.vertical,
        capSettings: {
          daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
          dayCap: {
            monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
            tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
            wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
            thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
            friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
            saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
            sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
          },
          enableDayCap: campaignState.enableDayCap,
          monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
          total: 500,
          weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
        },
        excludedBrandIdToggle: campaignState.excludedBrandIDAction,
        excludeMbrandID: campaignState.excludeMbrandID,
        publisherID: RowDataPay?.mPubID,
        mPubID: RowDataPay?.mPubID,
        publisherName: campaignState.publisherName,
        updatedOn: '',
        createdOn: '',
        source: campaignState.description,
        status: campaignState.status,
        website: "",
      }
      if (payload.publisher) {
        // Ensure 'campaignCount' is removed safely
        let { campaignCount, ...rest } = payload.publisher; // Exclude campaignCount
        payload.publisher = rest; // Update the publisher object
      }
      // console.log('payload', payload)



      let requestData
      if (rowData) {
        requestData = {
          ID: rowData.ID,
          SORTID: rowData?.SORTID,
          advertiserStatus: rowData?.publisher?.status,
          createdOn: rowData?.createdOn,
          campaignname: campaignState.campaignname,
          campaignType: rowData?.campaignType,
          publisher: RowDataPay,
          vertical: campaignState.vertical,
          capSettings: {
            daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
            dayCap: {
              monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
              tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
              wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
              thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
              friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
              saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
              sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
            },
            enableDayCap: campaignState.enableDayCap,
            monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
            total: 500,
            weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
          },
          excludedBrandIdToggle: campaignState.excludedBrandIDAction,
          excludeMbrandID: ((Object.keys(campaignState.excludeMbrandID).length === 0) ? rowData?.excludeMbrandID : campaignState.excludeMbrandID), //campaignState.excludeMbrandID,
          publisherID: rowData?.publisherID,
          mPubID: RowDataPay?.mPubID,
          publisherName: rowData?.publisher?.SORTID,
          source: campaignState.description,
          status: campaignState.status,
          website: "",
          campaignClass: campaignType
        };
      }
      try {
        if (campaignState.editFlag) {
          try {
            await dispatch(updatePublisherCampAsync(requestData)).then(() => setSuccessMessage('Campaign Updated Successfully'));
            // setSuccessMessage('Campaign Updated Successfully');
          } catch (error) {
            console.log("Error in Updating the Campaign", error);
          }
        } else {
          try {
            console.log(payload, 'payload')
            await dispatch(createPublisherCampAsync(payload));
            setSuccessMessage('Campaign Created Successfully');
          } catch (error) {
            console.log("Error in Creating the Campaign", error);
          }
        }
        dispatch(setCampFlag(false));
        setOpenPopup(false);
        setSnackbarOpen(true);
        // !rowData && fetchInitialData();
        if (rowData && searchInput) { searchPublisherCampaigns(searchInput, dispatch, 'campaign') }
        else if (searchFilterData.length > 0 && rowData) {
          const payload = {
            "mPubIds": searchFilterData
          }

          searchFilterData.length > 0 && filterPublisherSearch(payload, dispatch);
        }
        fetchPublisherCampApiData(dispatch, null)
        fetchCompApiData(dispatch);
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create publisher');
        setSnackbarOpen(false);
      }
      dispatch(setIsoLoading(false));
      dispatch(resetForm());
      dispatch(setExcludeMbrandId({}));
      dispatch(storeBrands([]))
      setBrands([])
    }
  };
  const cancelButton = () => {
    setOpenPopup(false);
    dispatch(resetForm());
    // setSelectedFileName('');
    setBrands([])
    dispatch(storeBrands([]))
    dispatch(setExcludeMbrandId({}))
    setPublisherNameError(false);
    setCampaignNameError(false);
    setVerticalNameError(false);

  };


  // console.log("campaign Type of Edit ", campaignType)


  const handleCampaignType = (event: SelectChangeEvent) => {
    setCampaignType(event.target.value);
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [state, setState] = React.useState({
    clicks: true,
    tcpa: false,
    leads: false,
    email: false,
    linkout: false
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    if (
      event.target.checked ||
      (state.clicks || state.tcpa || state.leads || state.email || state.linkout) // check if any checkbox was previously selected
    ) {
      setCampaignTypeError(false);
    }

    // console.log("Checked::::", event.target.checked)
  };

  // Function to handle publisher change
  const handlePublisherChange = (value: any) => {
    setPublisherNameError(false);
    // handleFieldChange("publisherName", value);

    const publisherName = fullDataPubli.find((row: any) => row?.mPubID === value)
    dispatch(setFields({ field: "publisherName", value: publisherName.SORTID }));
    dispatch(setVerticalFields({ field: "mPubID", value }));
    // Fetch verticals based on selected publisher
    fetchVerticals(value)

  };
  // console.log('fullDataPubli--------', fullDataPubli)

  return (
    <div>
      <Dialog open={openPopup} fullWidth maxWidth="lg">
        <DialogTitle>
          {campaignState.editFlag ? "Update" : "Create"} Campaign
        </DialogTitle>
        <div className="close-button" onClick={cancelButton}>
          <CloseIcon style={{ fontSize: "32px" }} />
        </div>
        <DialogContent
          sx={{
            overflowY: { xs: "auto", md: "hidden" }, // Enable scroll only on small screens
            maxHeight: { xs: "80vh", md: "none" }, // Limit height on small screens, no limit on larger screens
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth style={{ marginBottom: '25px' }}>
                <InputLabel>Select Publisher*</InputLabel>
                <Select
                  disabled={campaignState.editFlag}
                  label="Select Publisher*"
                  fullWidth
                  value={campaignState.mPubID}
                  onChange={(e) => handlePublisherChange(e.target.value)}
                  error={publisherNameError}
                >
                  {fullDataPubli
                    .filter((pub: any) => pub.publisherName) // Filter out elements with undefined or null publisherName
                    .sort((a: any, b: any) => a.publisherName.localeCompare(b.publisherName))
                    .map((pub: any) => (
                      <MenuItem key={pub.mPubID} value={pub.mPubID}>
                        {`${pub.publisherName} - ${pub.mPubID}`}
                      </MenuItem>
                    ))}
                </Select>
                {publisherNameError && (
                  <div
                    style={{
                      color: "#d32f2f",
                      fontSize: "13px",
                      paddingLeft: "12px",
                    }}
                  >
                    Please select Publisher
                  </div>
                )}
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: "10px" }}>
                <InputLabel>Select Vertical*</InputLabel>
                <Select
                  disabled={!campaignState.mPubID || campaignState.editFlag}
                  label="Select Vertical*"
                  fullWidth
                  value={campaignState.vertical?.verticalID || ''}
                  onChange={(e) => {
                    setVerticalNameError(false);
                    // handleFieldChange('vertical', e.target.value);
                    handleVerticalChange(e.target.value)
                  }}
                  error={verticalNameError}
                >
                  {
                    verticalLoading ? <>Loading</> :
                      campaignState.verticalOptions.map((vertical: any) => (
                        <MenuItem key={vertical?.verticalID} value={vertical?.verticalID}>
                          {vertical?.verticalName}

                        </MenuItem>

                      ))}

                </Select>
                {verticalNameError && (
                  <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>
                    Please select Vertical
                  </div>
                )}
              </FormControl>

              <TextField
                label="Name*"
                fullWidth
                value={campaignState.campaignname}
                onChange={(e: { target: { value: any } }) => {
                  setCampaignNameError(false);
                  handleFieldChange("campaignname", e.target.value);
                }}
                error={campaignNameError}
                // helperText={
                //   campaignNameError ? "Campaign Name is mandatory" : ""
                // }
                margin="normal"
              />
              {campaignNameError && (
                <div
                  style={{
                    color: "#d32f2f",
                    fontSize: "13px",
                    paddingLeft: "12px",
                  }}
                > Campaign Name is mandatory</div>
              )}


              <FormControlLabel
                sx={{ marginTop: "8px" }}
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                control={
                  <Switch
                    checked={campaignState.status === "Active"}
                    onChange={() => dispatch(statusHandler())}
                  />
                }


              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">
                  Campaign Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={campaignType}
                  label="Campaign Type"
                  onChange={handleCampaignType}
                >
                  {
                    // Filter out elements with undefined or null publisherName
                    CAMPAIGN_TYPE_OPTIONS.map((pub: any, index: any) => (
                      <MenuItem key={pub.value} value={pub.value}>
                        {pub.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

            </Grid>

            <Grid item xs={12} md={6}>
              <MbrandExclusion {...props} brands={brands} loading={loading} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: "15px", paddingRight: "20px" }}>
          <Button onClick={cancelButton} style={{ color: "red" }}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCampaign}
          >
            {campaignState.editFlag ? "UPDATE" : "CREATE"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? "success" : "error"}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
      <Dialog open={open} onClose={handleCancel} maxWidth="xs">
        <DialogTitle
          style={{ backgroundColor: "rgb(198, 40, 40)", color: "white" }}
        >
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginTop: "10px", color: "black" }}>
            The following mbrand IDs already exist: {matchingIds.join(", ")}. Do
            you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}