import React, { useEffect, useMemo, useState } from 'react'
import PublisherStagingJSON from '../../Mock/PublishersStaging.json'
import { Box, Checkbox, Chip, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Typography } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import { mProgramActivationHeadCell } from '../../Component/Table/tableInterface'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { setData, setFlag } from '../../Store/Slices/PublisherTabApisData'
import { setBlockScroll, setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData'
import { resetFilters, setFields } from '../../Store/Slices/PublisherCampaignSlice'
import Search from '../../Component/Search/Search';
import CloseIcon from '@mui/icons-material/Close';


// import { mpartnerActivationHeadCells } from '../../Component/Table/tableInterface';
import { fetchFilterMpartnerCompApiData, fetchMpartnerApiData, fetchMpartnerCompApiData } from '../../Services/mPartnerActivation'
import { useLocation } from 'react-router-dom'
import { fetchVerticals } from '../../Services/verticalList'
import { RootState } from '../../Store'
import { useSelector } from 'react-redux'
import { filterByPublisherSearch } from '../../Services/publisherTabApis'

const MpartnerActivation = () => {

  // // const publisherTableData={

  // // }
  // const publisherTableData: any[] = PublisherStagingJSON.map((item, index) => {
  //   // publisherTableData[]
  //   const myObj = {
  //     publisherID: item.mPubID,
  //     publisherName: item.GroupName,
  //     createdOn: item.createdOn,
  //     website: item.values.website,

  //     // value:item.values

  //   }
  //   return myObj
  // })
  // //console.log("publisherTable", publisherTableData)

  // return (
  //   <Paper>
  //     <MMTable tableData={publisherTableData} tableHeadCell={mProgramActivationHeadCell} editComponentNavigation="/mPartners-config" />
  //   </Paper>
  // )

  const dispatch = useAppDispatch();
  const location = useLocation();
  const publisherTableData = useAppSelector((state) => state.publisherAccApi.data);
  const compApiData = useAppSelector((state) => state.publisherAccApi.compApiData);
  const isLoading = useAppSelector((state) => state.publisherAccApi.loading);
  const currentCursor = useAppSelector((state) => state.publisherAccApi.currentCursor);
  const hasMoreData = useAppSelector((state) => state.publisherAccApi.hasMoreData);
  const campaignState = useAppSelector((state) => state.campaign);
  const { verticalFilter, searchFilterData } = campaignState
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(setFlag(false));

    if (!searchInput && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      dispatch(setSearchInput(''));
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      setDebouncedInputValue('');
      fetchInitialData();
    } else if (verticalFilter.length !== 0 && searchInput === '') {
      const debounceTimer = setTimeout(() => {
        const payload = { vertical: verticalFilter };
        fetchFilterMpartnerCompApiData(payload, dispatch);
      }, 1000);
      return () => clearTimeout(debounceTimer);
    } else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput, verticalFilter,]);

  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(setSearchInput(''));
    fetchMpartnerCompApiData(dispatch, location);
  }, [])


  const fetchInitialData = () => {
    fetchMpartnerApiData(dispatch, null, location).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchMpartnerApiData(dispatch, currentCursor, location);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = compApiData.filter(function (item: any) {
        return (
          item.publisherID?.toString().toLowerCase().includes(userInput) ||
          item.publisherName?.toLowerCase().includes(userInput)
          // item.website?.toString().toLocaleLowerCase().includes(userInput) ||
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setData(filteredDataa));
    } else {
      dispatch(setData(publisherTableData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  // const handleSearchInput = useMemo(()=> (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const { value } = event.target
  //     dispatch(setSearchInput(value));
  //     searchTable(value)
  //   }, [searchTable])

  //New search functionality with debounce technique
  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch(setSearchInput(value));
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchInput, searchTable]);

  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchInput) {
        searchTable(searchInput);
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, [searchInput]);
  //--------------end search
  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };

  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className="card-heading">MPartners </Typography>
            <Typography className="card-sub-heading">
              MPartners Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Search value={searchInput} searchHandler={handleSearchInput} />
            {/* <Button variant="contained" startIcon={<AddIcon />}
                      onClick={() => {setOpenPopup(true); dispatch(setEditFlag(false))}}>
                      Create Publisher
                    </Button> */}
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch();
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable tableData={publisherTableData} tableHeadCell={mProgramActivationHeadCell} isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()} hasMoreData={hasMoreData} fetchInitialData={fetchInitialData}
          editComponentNavigation="/mPartners-config"
        />
      </Paper>
      {/* <CreatePublisher
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialData}
      /> */}
    </React.Fragment>
  )
}

export default MpartnerActivation