import React, { FC, useEffect } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Paper,
  IconButton,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik, FormikProvider, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { setTempData } from '../../../../Store/Slices/SEOWebsiteInfoApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { defaultUrlValidationSchema } from '../../validation';
import { updateWebSiteContent } from '../../../../Services/seoApis';
import {
  setApiLoading,
  setSnackbarOpen,
  setSuccessMessage,
} from '../../../../Store/Slices/SEOHandlerApisSlice';

const DefaultValueInfo: FC<any> = ({ submitRef, handleNext }) => {
  const websiteInfoFormData = useAppSelector(state => state.websiteInfoApi);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      pubId: websiteInfoFormData?.pubId,
      pubCampaignId: websiteInfoFormData?.pubCampaignId,
      defaultURL: websiteInfoFormData?.defaultURL,
    },
    onSubmit: values => {
      console.log('values',values);
      dispatch(setApiLoading(true));
      dispatch(setTempData({ ...websiteInfoFormData, ...values }));
      const updateContent = async () => {
        try {
          await updateWebSiteContent({ ...websiteInfoFormData, ...values });
          dispatch(setSuccessMessage('Updated Default Value'));
          dispatch(setSnackbarOpen(true));
          handleNext();
        } catch (error) {
          console.error('Error fetching data:', error);
          dispatch(setApiLoading(false));
        }
        dispatch(setApiLoading(false));
      };

      updateContent();
    },
    validationSchema: defaultUrlValidationSchema,
  });
  const { handleSubmit } = formik;

  useEffect(() => {
    submitRef.current = () => {
      handleSubmit();
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Paper elevation={3} className="ad-campaign-form">
      <Box>
        <FormikProvider value={formik}>
          <Grid container spacing={2} sx={{ mt: 1, mb: 4 }}>
            <Grid item xs={6}>
              <TextField
                label="Publisher ID"
                variant="outlined"
                name="pubId"
                value={formik.values.pubId}
                onChange={e => {
                  formik.handleChange(e);
                  //  dispatch(setWebsiteInfoField({ field: 'pubId', value: e.target.value }))
                }}
                error={formik.touched.pubId && Boolean(formik.errors.pubId)}
                // helperText={formik.touched.pubId && formik.errors.pubId}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Publisher Campaign ID"
                variant="outlined"
                name="pubCampaignId"
                value={formik.values.pubCampaignId}
                onChange={e => {
                  formik.handleChange(e);
                  //  dispatch(setWebsiteInfoField({ field: 'pubCampaignId', value: e.target.value }))
                }}
                error={
                  formik.touched.pubCampaignId &&
                  Boolean(formik.errors.pubCampaignId)
                }
                // helperText={formik.touched.pubCampaignId && formik.errors.pubCampaignId}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FieldArray
              name="defaultURL"
              render={arrayHelpers => (
                <>
                  {formik.values.defaultURL.map((url: any, index: any) => {
                    const touchedKey = getIn(
                      formik.touched,
                      `defaultURL.${index}.key`
                    );
                    const errorKey = getIn(
                      formik.errors,
                      `defaultURL.${index}.key`
                    );
                    const touchedValue = getIn(
                      formik.touched,
                      `defaultURL.${index}.value`
                    );
                    const errorValue = getIn(
                      formik.errors,
                      `defaultURL.${index}.value`
                    );

                    return (
                      <div key={index}>
                        <InputLabel
                          style={{
                            fontWeight: '600',
                            marginBottom: '10px',
                          }}
                        >
                          Default Values {index + 1}
                        </InputLabel>

                        <div
                          style={{
                            position: 'relative',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                              flex: 1,
                              marginBottom: '10px',
                              border: '2px solid #ccc',
                              borderRadius: '5px',
                              padding: '3% 2%',
                            }}
                          >
                            <TextField
                              label="Key"
                              variant="outlined"
                              name={`defaultURL[${index}].key`}
                              value={formik.values.defaultURL[index].key}
                              onChange={formik.handleChange}
                              fullWidth
                              error={touchedKey && Boolean(errorKey)}
                              helperText={
                                touchedKey && errorKey ? errorKey : ''
                              }
                            />
                            <TextField
                              label="Value"
                              variant="outlined"
                              name={`defaultURL[${index}].value`}
                              value={formik.values.defaultURL[index].value}
                              onChange={formik.handleChange}
                              fullWidth
                              style={{ marginTop: '3%' }}
                              error={touchedValue && Boolean(errorValue)}
                              helperText={
                                touchedValue && errorValue ? errorValue : ''
                              }
                            />
                            {/* <Box color="#db2424">
                                <ErrorMessage
                                  name={`defaultUrl.${index}.value`}
                                />
                              </Box> */}
                          </div>
                          <IconButton
                            aria-label="remove"
                            onClick={() => arrayHelpers.remove(index)}
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-10px',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                            }}
                          >
                            <CancelIcon
                              sx={{ color: '#f45d5d', fontSize: 40 }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                  <span
                    onClick={() => arrayHelpers.push({ key: '', value: '' })}
                    style={{
                      textDecoration: 'none',
                      color: '#18c0e2',
                      fontSize: '20px',
                      fontWeight: '500',
                      cursor: 'pointer',
                    }}
                  >
                    + Add More Value
                  </span>
                </>
              )}
            />
          </Grid>
        </FormikProvider>
      </Box>
    </Paper>
  );
};

export default DefaultValueInfo;
