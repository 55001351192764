import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  Box,
  Modal,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchUser, fetchAdvertiser, fetchPublisher, removeUserService, } from '../../service/User';
import { Order } from '../../Component/Table/tableInterface';
import { visuallyHidden } from '@mui/utils';
import UserSvgIcon from '../../assets/UserSvgIcon';
import Search from '../../Component/Search/Search';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CachedIcon from '@mui/icons-material/Cached';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import { DeleteUser } from './DeleteUser';
import SnackbarMessage from '../../Component/Snackbar';
import AddIcon from '@mui/icons-material/Add';

import { CircleLoader } from 'react-spinners';
export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  // bgcolor: 'background.paper',
  bgcolor: "#ffffff",
  border: '2px solid #0AE;',
  boxShadow: 24,
  p: 2,
};
export interface UserData {
  id?: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  cognitoGroup: string;
  UserCreateDate?: string;
  UserLastModifiedDate?: string;
  Enable?: boolean;
  userRole?: 'admin' | "vendor" | 'Publisher' | 'Super-Admin' | string
}

interface headerCell {
  id: keyof UserData;
  label: string;
  numeric: boolean;
  key: string


}
const userHeaderCell: headerCell[] = [
  {
    id: 'userName',
    label: "User Name",
    numeric: false,
    key: "userName"
  },
  {
    id: 'firstName',
    label: "First Name",
    numeric: false,
    key: "firstName"
  },
  {
    id: 'lastName',
    label: "Last Name",
    numeric: false,
    key: "lastName"
  },
  {
    id: "email",
    label: "Email",
    numeric: false,
    key: "email"
  },
  {
    id: "cognitoGroup",
    label: "Cognito Group",
    numeric: false,
    key: "email"
  },


]

interface MUITableProps {
  data: UserData[];
  modalCloseHandler: () => void;
  modalOpenHandler: () => void;
  deleteModalOpenHandler: () => void;
  setEditData: (data: UserData) => void;
  setDeleteData: (data: UserData) => void;
}

const PublisherUser = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[] | any>([])
  const [searchValue, setSearchValue] = useState<string>("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditModalData, setIsEditModalData] = useState<UserData | any>({})
  const [isDeleteModalData, setIsDeleteModalData] = useState<UserData | any>({})
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const fetchData = async () => {
    setLoading(true)
    Promise.all([fetchUser()]).then(([userRes]) => {
      setData(userRes.result)
      setLoading(false)
      // setLoading(false)
    }).catch((error) => {
      setLoading(true)
    })
  }
  useEffect(() => {
    fetchData()
  }, [isRefresh])


  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value) {
      setSearchValue(value)
      const filteredList = data.filter((user: UserData) =>
        user.userName.toLowerCase().includes(value.toLowerCase()) ||
        user.firstName.toLowerCase().includes(value.toLowerCase()) ||
        user.lastName.toLowerCase().includes(value.toLowerCase()) ||
        user.email.toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredList)
    } else {
      setSearchValue(value)
      // setData(data)
      await fetchData()
    }

    // handleSearch(); // Trigger search on each change
  };

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleIsEditModalClose = () => {
    setIsEditModalOpen(false)
  }

  const handleIsEditModalOpen = () => {
    setIsEditModalOpen(true)
  }

  const handleIsEditModalData = (data: any) => {
    setIsEditModalData(data)
  }

  const handleIsDeleteModalOpen = () => {
    setIsDeleteModalOpen(true)
  }

  const handleIsDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }
  const handleIsDeleteModalData = (data: any) => {
    setIsDeleteModalData(data)
  }


  const handleRefresh = () => {
    console.log("ReFRESH CLICK")
    setIsRefresh(isRefresh);
    fetchData()
  }
  const headTextStyle = {

    fontSize: "20px",
    fontWeight: "bold"


  }

  const handleSnackBarData = (type: string, isOpen: boolean, message: string) => {
    // Use the setter function returned by useState to update the state
    setSnackbarOpen(isOpen);
    setSnackbarMessage(message)
    setSnackbarType(type);
  };

  return (
    <>
      <Paper elevation={3} className="card">
        <Box className="user_Container">
          <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Box>

              <Typography className="card-heading">User List</Typography>
              <Typography className="card-sub-heading">
                User Summary
              </Typography>

            </Box>
            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
              <Box className="ip-box">
                <Search value={searchValue} searchHandler={handleChange} />
              </Box>
              <Box className="create-user-icon" onClick={() => { setIsModalOpen(true) }}>
                {/* <PersonAddAltIcon sx={{ color: '#737791' }} /> */}

                {/* <Button variant="contained" startIcon={<PersonAddAltIcon sx={{ color: '#ffff' }} />}   */}
                <Button sx={{ padding: '6px 40px 6px 12px', backgroundColor: "#0ae" }} variant="contained" startIcon={<AddIcon />}
                >
                  Create User
                </Button>
              </Box>
              <Box className="refresh-button" onClick={handleRefresh}>
                <Tooltip title='Refresh'><CachedIcon sx={{ color: '#737791' }} /></Tooltip>
              </Box>

            </Box>
          </Box>
          <Box className='user_body'>
            {loading ? <Box className="loading-spinner-container" sx={{
              minHeight: "500px"
            }}>

              <CircleLoader color="#00BFFF" loading={loading ? true : false} size={60} />
            </Box> :
              <UserTable data={data} modalCloseHandler={handleIsEditModalClose} modalOpenHandler={handleIsEditModalOpen} deleteModalOpenHandler={handleIsDeleteModalOpen} setEditData={handleIsEditModalData} setDeleteData={handleIsDeleteModalData} />}
          </Box>

        </Box>
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CreateUser
              modalCloseHandler={handleClose}
              refreshHandler={handleRefresh}
              userList={data}
              snackBarDataHandler={handleSnackBarData} />
          </Box>

        </Modal>
        <Modal
          open={isEditModalOpen}
          onClose={handleIsEditModalClose}
          aria-labelledby="modal-update-user"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <CreateUser   modalCloseHandler={handleClose} /> */}
            <UpdateUser
              data={isEditModalData}
              modalCloseHandler={handleIsEditModalClose}
              refreshHandler={handleRefresh}
              snackBarDataHandler={handleSnackBarData} />
          </Box>

        </Modal>

        <DeleteUser data={isDeleteModalData} isDeleteModalOpen={isDeleteModalOpen} modalCloseHandler={handleIsDeleteModalClose}
          refreshHandler={handleRefresh}
        />
      </Paper>
      <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }} type={snackbarType} message={snackbarMessage} />
    </>
  )
}

export const UserTable: React.FC<MUITableProps> = ({ data, modalOpenHandler, deleteModalOpenHandler, setEditData, setDeleteData }) => {
  // const [sortColumn, setSortColumn] = useState<keyof UserData | string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  //  const [sortColumn, setSortColumn] = useState<string | null>("userName");
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof UserData>('userName');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  // Theme configuration
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            backgroundColor: "#EAECF0",
            color: "#737791"
          },

        },
      },
    },
  });

  const handleRequestSort = (property: keyof UserData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedData = React.useMemo(() => {
    const comparator = (a: UserData, b: UserData) => {
      const aValue = a[orderBy ?? 'userName'] ?? "";
      const bValue = b[orderBy ?? 'userName'] ?? "";

      if (order === 'asc') {
        return aValue < bValue ? -1 : 1;
      } else {
        return aValue > bValue ? -1 : 1;
      }
    };

    return [...data].sort(comparator);
  }, [data, orderBy, order]);

  const handleEditIcon = (rowId: any) => {
    const dataForEdit = sortedData.filter((row) => row.id === rowId)
    console.log("data for edit", dataForEdit)
    setEditData(dataForEdit[0]);
    modalOpenHandler()

  }

  const handleDeleteIcon = (rowId: any) => {
    const dataForEdit = sortedData.filter((row) => row.id === rowId)
    setDeleteData(dataForEdit[0]);
    deleteModalOpenHandler()

  }
  let userRole = localStorage.getItem("userRole")
  const canDelete = (currentUser: string | null, rowCognitoGroup: string) => {
    if (currentUser === "Super-Admin") {
      return true
    }

    if (currentUser === 'admin' && rowCognitoGroup !== "MM-Super-Admin") {
      return true;
    }
    return false;
  }
  return (
    <ThemeProvider theme={theme} >
      <TableContainer component={Paper}>
        <Box className='no-more-tables responsive-tbl'>
          <Table size="small">
            <TableHead>
              <TableRow>
                {
                  userHeaderCell.map((item, index) => {
                    return (
                      <TableCell
                        key={item.id}
                        //   align={headCell.numeric ? 'right' : 'left'}
                        align={'left'}
                        sortDirection={orderBy === item.id ? order : false}  >

                        <TableSortLabel
                          active={orderBy === item.id}
                          direction={orderBy === item.id ? order : 'asc'}
                          onClick={(e) => {
                            handleRequestSort(item.id)
                          }}
                        >
                          {item.label}
                          {orderBy === item.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>


                      </TableCell>
                    )
                  })
                }

                {canDelete(userRole, '') && <TableCell >Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.length > 0 ? (
                sortedData.map((row, index) => (
                  <TableRow key={row.id} className='userTables'>
                    <TableCell data-title="UserName" sx={{ lineHeight: 2.5 }}>{row.userName}</TableCell>
                    <TableCell data-title="firstName">{row.firstName}</TableCell>
                    <TableCell data-title="lastName">{row.lastName}</TableCell>
                    <TableCell data-title="email">{row.email}</TableCell>
                    <TableCell data-title="cognitoGroup">{row.cognitoGroup}</TableCell>
                    {canDelete(userRole, row.cognitoGroup) && (
                      <TableCell data-title="Action">
                        <IconButton onClick={() => handleEditIcon(row.id)} size="small">
                          <Tooltip title="Edit">
                            <EditIcon color='primary' />
                          </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => { handleDeleteIcon(row.id); console.log(`Delete ${row.id}`); }} size="small">
                          <Tooltip title="Delete">
                            <DeleteIcon color='error' />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: 'center', paddingBottom: '10px', marginTop: '-5px', fontSize: 'large' }}>
                    No Result Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        </Box>
      </TableContainer>
      <Box>
        <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }} type={snackbarType} message={snackbarMessage} />
      </Box>
    </ThemeProvider>
  );
};






export default PublisherUser;