import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { baseURLConfig } from '../../../config/envConfig';
import { generateHeader } from '../../../service/Auth';
import axios from 'axios';
import { CircleLoader } from 'react-spinners';
import {
  useFormik,
  FormikProvider,
  FieldArray,
  ErrorMessage,
  getIn,
} from 'formik';
import * as Yup from 'yup';
import AdminTestAPI from './AdminTestAPI';
import AdminTCPA_API from './AdminTCPAAPI';
import EmailAPI from './EmailAPI';

const validationSchema = Yup.object().shape({
  verticalId: Yup.string().required('Vertical is required'),
  apiType: Yup.string().required('Product is required'),
});

const ApiTypePopup: React.FC<any> = ({
  isOpen,
  onClose,
  onProceed,
  verticalData,
  apiDetails,
}) => {
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  const ApiTypes = [
    { typeId: 1, typeName: 'Clicks' },
    // { typeId: 2, typeName: 'Leads' },
    { typeId: 3, typeName: 'TCPA' },
    { typeId: 4, typeName: 'Email' },
    // { typeId: 5, typeName: 'Link Out' },
  ];

  const formik: any = useFormik({
    initialValues: {
      verticalId: apiDetails?.verticalId || '',
      apiType: apiDetails?.apiType || '',
    },
    onSubmit: (values: any) => {
      onProceed(values);
    },
    validationSchema,
  });

  useEffect(() => {
    if (verticalData?.isFetched) {
      setPageLoading(false);
    }
  }, [verticalData]);

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="xs"
      fullWidth
    >
      {pageLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
            minHeight: '250px',
            alignItems: 'center',
          }}
        >
          <CircleLoader loading={pageLoading} color="#00BFFF" size={80} />
        </div>
      ) : (
        <>
          <DialogTitle>Select Type</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  sx={{ marginTop: '9px' }}
                  error={Boolean(
                    formik.errors.verticalId && formik.touched.verticalId
                  )}
                >
                  <InputLabel id="vertical-type-label">
                    Vertical Type
                  </InputLabel>
                  <Select
                    labelId="vertical-type-label"
                    value={formik.values.verticalId}
                    onChange={formik.handleChange}
                    label="Vertical Type"
                    name="verticalId"
                  >
                    {verticalData?.data?.map((option: any) => (
                      <MenuItem
                        key={option.verticalID}
                        value={option.verticalID}
                      >
                        {option.verticalName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.errors.verticalId && formik.touched.verticalId ? (
                  <Box color="#db2424">{formik.errors.verticalId}</Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  sx={{ marginTop: '9px' }}
                  error={Boolean(
                    formik.errors.apiType && formik.touched.apiType
                  )}
                >
                  <InputLabel id="adv-type-label">Select Product</InputLabel>
                  <Select
                    labelId="adv-type-label"
                    value={formik.values.apiType}
                    onChange={formik.handleChange}
                    label="Select Product"
                    name="apiType"
                  >
                    {ApiTypes?.map((item: any) => (
                      <MenuItem value={item.typeId} key={item.typeId}>
                        {item.typeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.errors.apiType && formik.touched.apiType ? (
                  <Box color="#db2424">{formik.errors.apiType}</Box>
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => formik.handleSubmit()}
              variant="contained"
              color="primary"
            >
              OK
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const TestAPITools = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(true);
  const [apiDetails, setApiDetails] = useState<any>({
    verticalId: '',
    apiType: '',
  });
  const [verticalResponse, setVerticalResponse] = useState<any>({
    data: '',
    isFetched: false,
    error: '',
  });
  const fetchVerticals = async () => {
    try {
      const headers = await generateHeader();
      const response = await axios.get(
        `${baseURLConfig.baseURl}/getVerticalList`,
        { headers }
      );
      setVerticalResponse({
        data: response?.data?.data,
        isFetched: true,
        error: '',
      });
    } catch (error) {
      const errorMessage =
        axios.isAxiosError(error) && error.response
          ? error.response.data.message || 'Failed to fetch verticals'
          : 'Network error';
      setVerticalResponse({ data: '', isFetched: true, error: errorMessage });
    }
  };

  const tittle = [
    'Test API',
    'Clicks API',
    'Leads API',
    'TCPA API',
    'Email API',
  ];
  const displayAPIPage = () => {
    switch (apiDetails?.apiType) {
      case 1:
        return <AdminTestAPI verticalId={apiDetails?.verticalId}  />;
      case 2:
        return <>Leads API</>;
      case 3:
        return <AdminTCPA_API verticalId={apiDetails?.verticalId} />;
      case 4:
        return <EmailAPI verticalId={apiDetails?.verticalId} />;
      case 5:
        return <></>;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    fetchVerticals();
  }, []);
  return (
    <Paper elevation={3} className="card" sx={{ minHeight: '100vh' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        p={3}
        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Box>
          <Typography className="card-heading">
            {apiDetails?.apiType ? tittle[apiDetails?.apiType] : tittle[0]}
          </Typography>
          <Typography className="card-sub-heading">
            Test your campaigns with multiple options
          </Typography>
        </Box>
        <Box
          display="flex"
          // gap="16px"
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            mt: { xs: '16px', md: '0' },
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Button
            variant="contained"
            // startIcon={<AddIcon />}
            onClick={() => {
              setIsPopupOpen(true);
            }}
            sx={{ backgroundColor: '#0ae' }}
          >
            Reset Test Page
          </Button>
        </Box>
      </Box>
      {displayAPIPage()}
      {isPopupOpen && (
        <ApiTypePopup
          isOpen={isPopupOpen}
          verticalData={verticalResponse}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          onProceed={(value: any) => {
            setApiDetails(value);
            setIsPopupOpen(false);
          }}
          apiDetails={apiDetails}
        />
      )}
    </Paper>
  );
};

export default TestAPITools;
