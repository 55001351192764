
import { fetchToken, generateHeader } from '../service/Auth';
import { baseURLConfig } from '../config/envConfig';
import axios from 'axios';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { appendTcpaData, appendTcpFullData, setCurrentCursor, setHasMoreData, setTcpaApiLoading, setTcpaData, setTcpaFilterSearchData, setTcpafilterSearchFlag, setTcpaFullData, setTcpaLoading } from '../Store/Slices/tcpaCampSlice';
import { setBlockScroll, setIsoLoading, setNoDataFlag } from '../Store/Slices/advertiserTabApisData';
import { getCampaignStatus } from '../Utils/utils';
import { useAppDispatch } from '../Store/hooks';
import { useDispatch } from 'react-redux';

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;

const baseURL = baseURLConfig.baseURl;
const tcpaFetchUrl = `${baseURL}/tcpa/campaign?limit=15`
const tcpaCreateUrl = `${baseURL}/tcpa/campaign`
const deleteTcpaAPI = `${baseURL}/tcpa/campaign?tcpaCampaignId=`


export const fetchTcpaCampaignData = async (
  dispatch: DispatchType,
  currentCursor: string | null,
) => {
  const baseURL = baseURLConfig.baseURl;
  try {
    dispatch(appendTcpaData([]));
    const headers = await generateHeader();
    // dispatch(setLoading(true));
    let url = tcpaFetchUrl;
    if (currentCursor !== null) {
      dispatch(setTcpaLoading(true));
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url, { headers });
    const jsonData = await response.json();
    const cursorEncode = encodeURIComponent(jsonData.meta.cursor);
    console.log("jsonData", jsonData)
    const filteredData = jsonData.data.map((item: any) => ({
      tcpaCampaign_id: item?.tcpaCampaignId,
      tcpaCampaign_name: item?.tcpaCampName,
      Adv_ID: item?.advertiserId,
      Advertiser_Name: item?.advertiserName,
      Status: item.status,
      vertical: item.vertical.verticalDisplay,
    }));
    //  dispatch(setFullData(jsonData.data))
    // dispatch(appendFullData(jsonData.data))

    dispatch(appendTcpFullData(jsonData.data));
    dispatch(appendTcpaData(filteredData));
    dispatch(setCurrentCursor(cursorEncode));
    dispatch(setHasMoreData(jsonData.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setTcpaLoading(false));
    // dispatch(setLoading(false));
  }
};

export const fetchTcpaSearchResult = async (inputValue: string, dispatch: DispatchType) => {
  try {
    dispatch(setIsoLoading(true));
    const headers = await generateHeader();
    const encodedValue: string = encodeURIComponent(inputValue);
    const response = await axios.get(`${baseURL}/tcpa/campaign/search?campaignType=tcpaCampaign&searchKey=${encodedValue}`, { headers });

    const filteredData = response.data.data.map((item: any) => {
      // const advertiserName = item?.publisherName;
      // const formattedAdvertiserName = advertiserName ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '') : '';
      // const pauseStatus=getCampaignStatus(item.values.configTimeOfDay)
      return {
        tcpaCampaign_id: item?.tcpaCampaignId,
        tcpaCampaign_name: item?.tcpaCampName,
        Adv_ID: item?.advertiserId,
        Advertiser_Name: item?.advertiserName,
        Status: item.status,
        vertical: item.vertical.verticalDisplay,
      };
    });
    if (encodedValue && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }

    dispatch(setIsoLoading(false));
    dispatch(appendTcpaData(filteredData));
    dispatch(appendTcpFullData(response.data.data));

  } catch (error) {
    console.error("Error fetching search results:", error);
    dispatch(setIsoLoading(false)); // Set loading flag to false in case of an error
    // You can dispatch additional actions or handle the error as needed
  }
};

export const filterTcpaSearch = async (palyoad: Object, dispatch: DispatchType) => {
  try {
    dispatch(setTcpafilterSearchFlag(true));
    dispatch(setIsoLoading(true));
    const headers = await generateHeader();
    const response = await axios.post(`${baseURL}/filterTcpaCampaign?campaignType=tcpaCampaign`, palyoad, { headers });
    const filteredData = response.data.data.map((item: any) => {
      // const advertiserName = item?.publisherName;
      // const formattedAdvertiserName = advertiserName ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '') : '';
      // const pauseStatus=getCampaignStatus(item.values.configTimeOfDay)
      return {
        tcpaCampaign_id: item?.tcpaCampaignId,
        tcpaCampaign_name: item?.tcpaCampName,
        Adv_ID: item?.advertiserId,
        Advertiser_Name: item?.advertiserName,
        Status: item.status,
        vertical: item.vertical.verticalDisplay,
      };
    });

    if (palyoad && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }

    dispatch(setIsoLoading(false));
    dispatch(setTcpaFilterSearchData(filteredData));
    dispatch(appendTcpaData(filteredData));
    dispatch(appendTcpFullData(response.data));
  } catch (error) {
    dispatch(setTcpafilterSearchFlag(false));
    console.error("Error fetching search results:", error);
    dispatch(setIsoLoading(false)); // Set loading flag to false in case of an error
    // You can dispatch additional actions or handle the error as needed
  }
};

// export const createTcpaCamp = async (payload: any) => {

//     try {
//         const headers = await generateHeader();
//         const response = await axios.post(tcpaCreateUrl, payload, { headers });
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

export const updateStatusTcpaCamp = async (tcpaCampId: any, ID: any, requestData: any) => {
  try {
    const headers = await generateHeader();
    //   tcpa/campaign/status?tcpaCampaignId=62355&campaignType=tcpaCampaign
    const response = await axios.put(`${baseURL}/tcpa/campaign/status?tcpaCampaignId=${tcpaCampId}&campaignType=${ID}`, requestData, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const createTcpaCamp = async (payload: any, method: 'post' | 'put') => {
  try {
    const headers = await generateHeader();
    const response = await axios({
      url: tcpaCreateUrl,
      method: method,
      data: payload, // For methods like GET, data will be ignored
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const deleteTcpaCamp = async (payload: any) => {
  try {
    const headers = await generateHeader();
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${deleteTcpaAPI}${payload}`,
      headers: headers,
      data: payload.data
    };
    const response = await axios.request(config)
    // const response = await axios.delete(API_BASE_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};





export const fetchTCPA_LISTING = async (queryParams: any) => {
  const headers = await generateHeader();
  const tcpaBaseURL = baseURLConfig.TCPA_BASE_URL;
  const response = await axios.get(tcpaBaseURL, {
    params: queryParams
  })


  return response.data;
}